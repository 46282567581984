<template>
  <VerticalNavLayout
    style="background-color: rgb(var(--v-theme-background))"
    v-if="venue?.id"
  >
    <!-- 👉 navbar -->
    <template #navbar>
      <VRow justify="end" class="gap-3 mr-1">
        <VenueName />
        <Profile />
      </VRow>
    </template>

    <!-- 👉 Drawer content -->
    <template #navigation-drawer-content>
      <DrawerContent />
    </template>

    <!-- 👉 Pages -->
    <div class="layout-page-content">
      <RouterView />
      <ReceiveOrderEvents />
    </div>
  </VerticalNavLayout>
</template>

<style scoped>
@import "@/styling/layout.css";
</style>

<script setup lang="ts">
import { onMounted, ref, watch } from "vue";
import { RouterView } from "vue-router";
import { cache, cachedKeys } from "@/utils/cache";
import { socket as SocketService } from "@/services/websocketService";
import Profile from "@/components/Profile.vue";
import { getToken } from "@/utils/token";
import VenueName from "@/components/VenueName.vue";
import VerticalNavLayout from "@/@layouts/components/VerticalNavLayout.vue";
import DrawerContent from "@/components/DrawerContent.vue";
import { useVenueUserStore } from "@/views/waiters/stores/venueUserStore";
import ReceiveOrderEvents from "@/backgroundReceivers/ReceiveOrderEvents.vue";
import { useVenueStore } from "@/stores/venueStore";
import type { VenueType } from "@/types/Venue";
import { useDrinkStore } from "@/views/menus/drinks/stores/drinksStore";

const venueStore = useVenueStore();
useVenueUserStore();
useDrinkStore();

const venue = ref({} as VenueType);
const venueId = cache.getCache(cachedKeys.VENUE_ID);
const socket = SocketService.init(venueId);
const token = getToken();
const mobile = ref(false);
const collapsed = ref(false);

function isMobile(): boolean {
  return screen.width <= 900;
}

watch(
  async () => await venueStore.getVenue(),
  async () => {
    venue.value = await venueStore.getVenue();
  }
);

onMounted(async () => {
  socket.venueSocket.auth = {
    token,
  };
  await socket.venueSocket.connect();
  mobile.value = isMobile();
  collapsed.value = mobile.value;
});
</script>
