import { ref, onMounted, onUnmounted, type Ref } from "vue";

export function useConnection(): { online: Ref<boolean> } {
  const online = ref<boolean>(navigator.onLine);

  const updateOnlineStatus = (): void => {
    online.value = navigator.onLine;
  };

  onMounted(() => {
    window.addEventListener("online", updateOnlineStatus);
    window.addEventListener("offline", updateOnlineStatus);
  });

  onUnmounted(() => {
    window.removeEventListener("online", updateOnlineStatus);
    window.removeEventListener("offline", updateOnlineStatus);
  });

  return { online };
}
