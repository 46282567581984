import { ref } from "vue";
import type { VenueType } from "@/types/Venue";
import { defineStore } from "pinia";
import { cachedKeys } from "@/utils/cache";
import { NewVenueService } from "@/services/newVenueService";

export const useVenueStore = defineStore(cachedKeys.VENUE, () => {
  const venue = ref(null as VenueType | null);

  const getVenue = async (): Promise<VenueType> => {
    if (!venue.value) {
      await syncVenue();
      return venue.value!;
    }

    return venue.value;
  };

  function initStore() {
    new NewVenueService().getVenue().then((venueResponse) => {
      if (!venueResponse) return;

      venue.value = venueResponse;
    });
  }

  const syncVenue = async () => {
    venue.value = await new NewVenueService().getVenue();
  };

  initStore();

  return {
    getVenue,
    syncVenue,
  };
});
