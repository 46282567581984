<template>
  <tr v-if="editDrink !== {}">
    <td>
      {{ editDrink.originalName }}
    </td>
    <td class="drink_image">
      <div class="py-2">
        <n-image width="50" :src="editDrink.imageUrl" />
      </div>
    </td>
    <td class="drink_name">
      <div
        v-show="!nameEditable"
        @click="nameEditable = true"
        style="cursor: pointer"
      >
        {{ editDrink.name }}
      </div>
      <n-input
        v-show="nameEditable"
        v-model:value="editDrink.name"
        @update:value="handleNameChange"
        @keydown="nameKeyDown"
      />
    </td>
    <td v-if="editDrink.price && editDrink.sizes && !editDrink.sizes.length">
      <div
        v-if="!priceEditable"
        style="
          min-width: 10rem;
          display: flex;
          flex-direction: row;
          gap: 10px;
          justify-content: center;
          align-items: center;
        "
      >
        <PriceFormatter
          :amount="
            multiplyPriceByCurrency(
              editDrink.price.amount!,
              currency
            )
          "
          :currency="currency"
        />
        <v-btn
          color="secondary"
          variant="text"
          density="compact"
          icon="mdi-pencil"
          @click="priceEditable = true"
        ></v-btn>
      </div>
      <v-text-field
        v-if="priceEditable"
        type="number"
        :suffix="Currencies[currency]"
        placeholder="New price"
        style="min-width: 10rem"
        :append-inner-icon="priceHasChanged ? 'mdi-check' : ''"
        v-model="editDrink.price.amount"
        @keydown="priceKeyDown"
        :class="{
          'green-checkmark': priceHasChanged,
        }"
        @click:append-inner="updateDrinksPrice"
      ></v-text-field>
    </td>
    <td v-if="editDrink.sizes && editDrink.sizes.length">
      <div class="sizes_td">
        <div v-for="size in editDrink.sizes" :key="size.quantity">
          <DrinkSize :size="size" v-on:updatedSize="updateDrinkSizes" />
        </div>
      </div>
    </td>
    <td v-if="editDrink.price">
      <n-checkbox
        v-model:checked="editDrink.enabled"
        :disabled="disableCheckbox()"
        @update:checked="updateDrinksAvailability"
      />
    </td>
  </tr>
</template>

<style scoped>
td {
  text-align: center;
}
.drink_image {
  min-width: 150px;
}
.price_input {
  min-width: 150px;
}
.drink_name {
  min-width: 150px;
}
.sizes_td {
  min-width: 250px;
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  justify-content: center;
}
.green-checkmark:deep(.v-icon) {
  color: green;
}
</style>

<script setup lang="ts">
import { NCheckbox, NImage, NInput, useNotification } from "naive-ui";
import type { PropType } from "vue";
import { computed, onMounted, ref } from "vue";
import type { Drink, Size } from "@/views/menus/drinks/types/drinks";
import { Currencies } from "@/types/Venue";
import { DrinkService } from "@/views/menus/drinks/services/drinkService";
import DrinkSize from "@/views/menus/drinks/components/DrinkSize.vue";
import { KeyType } from "@/types/System";
import PriceFormatter from "@/components/PriceFormatter.vue";
import {
  dividePriceByCurrency,
  multiplyPriceByCurrency,
} from "@/common/Amount";

const props = defineProps({
  drink: Object as PropType<Drink>,
});
const emits = defineEmits(["updatedDrink"]);

const editDrink = ref({} as Drink);
const drinkService = new DrinkService();
const notification = useNotification();
const nameEditable = ref(false);
const priceEditable = ref(false);
const currency = ref("");
const priceHasChanged = computed(
  () =>
    props.drink?.price.amount !==
    multiplyPriceByCurrency(editDrink.value.price.amount!, currency.value)
);
let timeout = undefined as number | undefined;

function nameKeyDown(event: KeyboardEvent) {
  switch (event.key) {
    case KeyType.ESCAPE:
      restoreDefaultNameValue();
      nameEditable.value = false;
      break;
    case KeyType.ENTER:
    case KeyType.TAB:
      nameEditable.value = false;
      break;
  }
}

function priceKeyDown(event: KeyboardEvent) {
  switch (event.key) {
    case KeyType.ESCAPE:
      restoreDefaultPriceValue();
      priceEditable.value = false;
      break;
    case KeyType.ENTER:
      updateDrinksPrice();
      break;
  }
}

const restoreDefaultNameValue = () => {
  if (props.drink?.name) editDrink.value.name = props.drink.name;
};

const restoreDefaultPriceValue = () => {
  if (props.drink) setEditDrink(props.drink);
};

const handleNameChange = async () => {
  clearTimeout(timeout);

  timeout = setTimeout(async () => {
    if (!editDrink.value.name) {
      notification.warning({
        content: "Drink name can't be empty",
        duration: 2000,
      });
      return;
    }
    await updateDrinksName();
  }, 1000);
};

const updateDrinksName = async () => {
  if (!editDrink.value.name) return;

  const updatedDrink = await drinkService.updateDrink(editDrink.value.id, {
    name: editDrink.value.name,
  });

  if (!updatedDrink) {
    notification.error({
      content: "Something went wrong updating drink's data",
      duration: 3000,
    });
    return;
  }

  setEditDrink({
    ...editDrink.value,
    ...updatedDrink,
  });
  emits("updatedDrink", editDrink.value);
};

const updateDrinksPrice = async (newPrice?: any) => {
  const price =
    newPrice && typeof newPrice == "number"
      ? newPrice
      : editDrink.value.price.amount;
  const objectToSent = {
    enabled: !price ? false : undefined,
    price: price ? multiplyPriceByCurrency(price, currency.value) : null,
  };
  const updatedDrink = await drinkService.updateDrink(
    editDrink.value.id,
    objectToSent
  );

  if (!updatedDrink) {
    notification.error({
      content: "Something went wrong updating drink's data",
      duration: 3000,
    });
    return;
  }

  setEditDrink({
    ...editDrink.value,
    ...updatedDrink,
  });
  priceEditable.value = false;
  emits("updatedDrink", editDrink.value);
};

const disableCheckbox = (): boolean => {
  if (!editDrink.value.sizes.length) return !editDrink.value.price.amount;

  return !editDrink.value.sizes.some((size) => size.price.amount);
};
const updateDrinksAvailability = async (value: boolean) => {
  const objectToSent = {
    enabled: value,
  };
  const updatedDrink = await drinkService.updateDrink(
    editDrink.value.id,
    objectToSent
  );

  if (!updatedDrink) {
    notification.error({
      content: "Something went wrong updating drink's data",
      duration: 3000,
    });
    return;
  }

  setEditDrink({
    ...editDrink.value,
    ...updatedDrink,
  });
  emits("updatedDrink", editDrink.value);
};

const updateDrinkSizes = async (size: Size) => {
  configureDrinkSizes(size);
  const result = await drinkService.updateDrinkExtras(editDrink.value.id, {
    sizes: editDrink.value.sizes.map((size) => ({
      quantity: size.quantity,
      price: size.price.amount,
      menuItem: size.menuItem,
    })),
  });

  if (!result) {
    notification.error({
      content: "Something went wrong updating drink sizes",
      duration: 2000,
    });

    return;
  }

  const newPrice = constructDefaultPriceToUpdate();
  await updateDrinksPrice(newPrice);

  if (everySizesDoesntHaveMenuItem()) await updateDrinksAvailability(false);
};

const everySizesDoesntHaveMenuItem = () =>
  editDrink.value.sizes.length &&
  editDrink.value.sizes.every((size) => size.menuItem == null);

const configureDrinkSizes = (updatedSize: Size) => {
  const sizeIndex = editDrink.value.sizes.findIndex(
    (size) => size.quantity == updatedSize.quantity
  );
  editDrink.value.sizes[sizeIndex] = updatedSize;
};

const constructDefaultPriceToUpdate = (): number | null => {
  const sizesWithAmount = editDrink.value.sizes.filter(
    (size) => size.price.amount !== null
  );
  const priceOfTheFirstSize = sizesWithAmount.length
    ? sizesWithAmount[0].price.amount
    : null;

  return priceOfTheFirstSize
    ? dividePriceByCurrency(priceOfTheFirstSize, currency.value)
    : null;
};

const setEditDrink = (updatedDrink: Drink) => {
  if (updatedDrink == ({} as Drink)) return;
  editDrink.value = {
    enabled: updatedDrink.enabled,
    id: updatedDrink.id,
    name: updatedDrink.name,
    originalName: updatedDrink.originalName,
    imageUrl: updatedDrink.imageUrl,
    menuItem: updatedDrink.menuItem
      ? updatedDrink.menuItem
      : { id: undefined, name: undefined },
    price: {
      amount:
        updatedDrink.price && updatedDrink.price.amount
          ? dividePriceByCurrency(
              updatedDrink.price.amount,
              updatedDrink.price.currency
            )
          : null,
      currency: updatedDrink.price.currency,
    },
    originalPrice: {
      amount:
        updatedDrink.originalPrice && updatedDrink.originalPrice.amount
          ? dividePriceByCurrency(
              updatedDrink.originalPrice.amount,
              updatedDrink.originalPrice.currency
            )
          : null,
      currency: updatedDrink.price.currency,
    },
    sizes: updatedDrink.sizes,
  };
};

onMounted(() => {
  if (props?.drink && props.drink !== ({} as Drink)) {
    setEditDrink(props.drink);
    currency.value = editDrink.value.price.currency;
  }
});
</script>
