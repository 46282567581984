<template>
  <span>
    {{ formattedDate }}
  </span>
</template>

<script setup lang="ts">
import { onMounted, ref } from "vue";

const props = defineProps({
  date: Date,
});
const formattedDate = ref();

const constructDateString = (date: Date | null): string | null => {
  if (!date) return null;

  const dateToHandle = new Date(date);
  const timeStamp = dateToHandle.getTime();
  const YEAR = 31536000000;

  const currentTimestamp = new Date().getTime();

  const timeDifference = currentTimestamp - timeStamp;

  const dateFormatWithoutYear = new Intl.DateTimeFormat(navigator.language, {
    month: "short",
    day: "2-digit",
  });
  const dateFormatWithYear = new Intl.DateTimeFormat(navigator.language, {
    year: "numeric",
    month: "short",
    day: "2-digit",
  });

  if (timeDifference < YEAR) {
    return dateFormatWithoutYear.format(dateToHandle);
  }

  return dateFormatWithYear.format(dateToHandle);
};

onMounted(() => {
  if (props.date) formattedDate.value = constructDateString(props.date);
});
</script>
