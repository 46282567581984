export interface VenueUser {
  id: string;
  email: string;
  role: VenueUserRole;
  venueId: string;
  displayName: string;
  waiterCode: string;
}

export interface NewWaiter extends VenueUser {
  password: string;
}

export enum VenueUserRole {
  MANAGER = "MANAGER",
  WAITER = "WAITER",
  ADMIN = "ADMIN",
  CASHIER = "CASHIER",
}
