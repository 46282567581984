<template>
  <n-button class="logo-button" color="transparent" @click="goToHome">
    <n-image width="60" height="40" src="/dion-logo.svg" preview-disabled />
  </n-button>
</template>

<style scoped></style>

<script setup lang="ts">
import { NImage, NButton } from "naive-ui";
import router from "@/router";

async function goToHome() {
  await router.push({ name: "dashboard" });
}
</script>
