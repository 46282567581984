<template>
  <v-btn
    color="primary"
    dark
    @click="downloadReport"
    style="z-index: 1; border-radius: 10px 0 0 10px"
  >
    Download (.{{ selectedOption }})
  </v-btn>
  <v-menu
    v-model="menuOpen"
    :close-on-content-click="false"
    :nudge-width="200"
    offset-y
  >
    <template v-slot:activator="{ props }">
      <v-btn
        color="primary"
        dark
        v-bind="props"
        style="z-index: 0; margin-left: -23px"
        append-icon="mdi-menu-down"
      >
        |
      </v-btn>
    </template>
    <v-list>
      <v-list-item
        v-for="(option, index) in options"
        :key="index"
        @click="selectOption(option)"
      >
        <v-list-item-title>{{ option }}</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script lang="ts" setup>
import { ref } from "vue";
import { currentMonthFirstDay } from "@/common/dates";
import { NewVenueService } from "@/services/newVenueService";

const menuOpen = ref(false);
const options = ["pdf", "xlsx"];
const selectedOption = ref("pdf");

const props = defineProps({
  fromDate: {
    type: Date || null,
    required: true,
  },
  toDate: {
    type: Date || null,
    required: true,
  },
});

const selectOption = async (option: string) => {
  selectedOption.value = option;
  menuOpen.value = false;
};

const downloadReport = async () => {
  const downloadReportProps = {
    fromDate: props.fromDate
      ? props.fromDate.toString()
      : new Date(currentMonthFirstDay()).toString(),
    toDate: props.toDate ? props.toDate.toString() : new Date().toString(),
    type: selectedOption.value,
  };
  await new NewVenueService().downloadPaymentsReport(downloadReportProps);
};
</script>
