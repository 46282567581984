<template>
  <div>
    <n-date-picker
      v-model:value="dates"
      type="daterange"
      clearable
      :default-time="[undefined, '23:59:59']"
      :default-calendar-start-time="previousMonthFirstDay()"
      :default-calendar-end-time="currentMonth()"
      :shortcuts="rangeShortcuts"
      @update-value="emitUpdatedDates"
    />
  </div>
</template>

<style scoped></style>

<script setup lang="ts">
import { onMounted, ref } from "vue";
import { NDatePicker } from "naive-ui";
import {
  currentMonth,
  currentMonthFirstDay,
  getLastMondayDate,
  getStartOfTheDay,
  previousMonthFirstDay,
  previousMonthLastDay,
} from "@/common/dates";

const emits = defineEmits(["datesUpdated"]);
const dates = ref(null as (number | Date)[] | null);
const rangeShortcuts = ref({
  Today: [getStartOfTheDay(), new Date()],
  "This week": [getLastMondayDate(), new Date()],
  "This month": [currentMonthFirstDay(), new Date()],
  "Last month": [previousMonthFirstDay(), previousMonthLastDay()],
});

const findRangeTitle = (selectedRange: Date[]) => {
  for (const [title, range] of Object.entries(rangeShortcuts.value)) {
    if (
      new Date(range[0]).getTime() === selectedRange[0].getTime() &&
      new Date(range[1]).getTime() === selectedRange[1].getTime()
    ) {
      return title; // Return the title if the ranges match
    }
  }
  return null; // Return null if no matching range is found
};

const emitUpdatedDates = (newDateRange: (number | Date)[] | undefined) => {
  const rangeTitle = newDateRange
    ? findRangeTitle(newDateRange.map((date) => new Date(date)))
    : null;

  emits("datesUpdated", {
    fromDate: newDateRange ? new Date(newDateRange[0]) : null,
    toDate: newDateRange
      ? new Date(new Date(newDateRange[1]).setHours(24, 0, 0))
      : null,
    rangeTitle: rangeTitle || "Custom Range",
  });
};

const constructDefaultDates = () => {
  dates.value = rangeShortcuts.value["This month"];
  emitUpdatedDates(rangeShortcuts.value["This month"]);
};

onMounted(async () => {
  constructDefaultDates();
});
</script>
