import { defineStore } from "pinia";
import { cachedKeys } from "@/utils/cache";
import { ref } from "vue";
import type {
  Drink,
  Option,
  DrinkFromResponse,
  CustomTreatFromResponse,
} from "@/views/menus/drinks/types/drinks";
import { DrinkService } from "@/views/menus/drinks/services/drinkService";

export const useDrinkStore = defineStore(cachedKeys.DRINKS, () => {
  const drinks = ref({} as Record<string, DrinkFromResponse>);
  const optionGroups = ref({} as Record<string, Option[]>);
  const customTreats = ref([] as CustomTreatFromResponse[]);
  function getDrinks() {
    return Object.values(drinks.value);
  }

  function getOptionGroups(): Record<string, Option[]> {
    return optionGroups.value;
  }

  const getCustomTreats = (): CustomTreatFromResponse[] => {
    return customTreats.value;
  };

  function updateDrink(newData: DrinkFromResponse) {
    drinks.value[newData.id] = newData;
  }

  function updateDrinkField(newData: Drink) {
    const currentDrink = drinks.value[newData.id];
    drinks.value[newData.id] = {
      ...currentDrink,
      ...newData,
    };
  }

  function setResponse({
    drinksFromResponse,
    optionGroupsFromResponse,
    customTreatsFromResponse,
  }: {
    drinksFromResponse: DrinkFromResponse[];
    optionGroupsFromResponse: Record<string, Option[]>;
    customTreatsFromResponse: CustomTreatFromResponse[];
  }) {
    for (const drink of drinksFromResponse) {
      drinks.value[drink.id] = drink;
    }
    optionGroups.value = optionGroupsFromResponse;
    customTreats.value = customTreatsFromResponse;
  }

  async function fetchVenueDrinks() {
    const result = await new DrinkService().getAllAvailableDrinks();
    if (!result) return;

    setResponse({
      drinksFromResponse: result.drinks,
      optionGroupsFromResponse: result.optionGroups,
      customTreatsFromResponse: result.customTreats,
    });
  }

  function initStore() {
    new DrinkService().getAllAvailableDrinks().then((drinkResponse) => {
      if (!drinkResponse) return;

      setResponse({
        drinksFromResponse: drinkResponse.drinks,
        optionGroupsFromResponse: drinkResponse.optionGroups,
        customTreatsFromResponse: drinkResponse.customTreats,
      });
    });
  }

  initStore();

  return {
    getDrinks,
    getOptionGroups,
    getCustomTreats,
    updateDrink,
    initStore,
    fetchVenueDrinks,
    updateDrinkField,
  };
});
