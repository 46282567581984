import { cache, cachedKeys } from "@/utils/cache";
import axios, { type AxiosInstance, type AxiosRequestConfig } from "axios";
import { getToken, setRefreshToken, setToken } from "@/utils/token";
import { Config } from "@/services/config";
import ErrorHandler from "@/handlers/errorHandler";
import { NewVenueService } from "@/services/newVenueService";

const API_HOST = import.meta.env.VITE_API_HOST;

export class AuthService {
  private token = getToken();

  private config: AxiosRequestConfig = {
    baseURL: `${API_HOST}/api/v1/venue`,
  };
  private client: AxiosInstance;

  constructor() {
    this.client = axios.create(this.config);
  }

  public async login(credentials: {
    email: string;
    password: string;
  }): Promise<void> {
    try {
      const result = await this.client.post("/user/login", credentials);
      const token = result.data.data.token;
      const refreshToken = result.data.data.refresh_token;

      setToken(token);
      setRefreshToken(refreshToken);
      window.localStorage.clear();

      const venueUser = await new NewVenueService().getVenueUserData();
      if (!venueUser) return;
      cache.setCache(cachedKeys.VENUE_ID, venueUser.venueId);
      cache.setCache(cachedKeys.ROLE, venueUser.role);
    } catch (error) {
      console.log(error);
      return Promise.reject(error);
    }
  }

  public async refreshToken(refreshToken: string) {
    try {
      const headers = new Config().getHeaders();
      this.client = axios.create({ ...this.config, headers });

      const result = await this.client.post("/user/token/refresh", {
        refresh_token: refreshToken,
      });
      const { token, refresh_token } = result.data.data;
      setToken(token);
      setRefreshToken(refresh_token);
      return true;
    } catch (error) {
      ErrorHandler.onError(error);
      return;
    }
  }
}
