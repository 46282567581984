class Cache {
  setCache(key: string, value: any) {
    window.localStorage.setItem(key, JSON.stringify(value));
  }
  getCache(key: string) {
    const value = window.localStorage.getItem(key);
    if (value) {
      return JSON.parse(value);
    }
  }
  deleteCache(key: string) {
    window.localStorage.removeItem(key);
  }
}

const cache = new Cache();

const enum cachedKeys {
  TOKEN = "token",
  REFRESH_TOKEN = "refresh_token",
  VENUE_ID = "venue_id",
  VENUE = "venue",
  ROLE = "role",
  ORDERING_ENABLED = "ordering_enabled",
  OFFERS = "offers",
  OFFER_ORDER = "offerOrder",
  TREAT_ORDER = "treatOrder",
  NEW_MESSAGE = "newMessage",
  LAST_VISITED_ROUTE = "lastVisitedRoute",
  WAITER_CALL = "waiterCalls",
  CONVERSATIONS = "conversations",
  MENUS = "MENUS",
  ITEMS = "ITEMS",
  VENUE_USERS = "venue_users",
  DRINKS = "DRINKS",
}

export { cache, cachedKeys };
