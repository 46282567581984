import { CurrencyDivider } from "@/types/Venue";

export interface NullableAmount {
  amount: null | number;
  currency: string;
}
export interface Amount {
  amount: number;
  currency: string;
}

export const formatPrice = (price: Amount) => {
  const language = navigator.language;
  const finalPrice = dividePriceByCurrency(price.amount, price.currency);
  const hasDecimals = finalPrice % 1 !== 0;
  return new Intl.NumberFormat(language, {
    style: "currency",
    currency: price.currency,
    minimumFractionDigits: hasDecimals ? 2 : 0,
    maximumFractionDigits: hasDecimals ? 2 : 0,
  }).format(finalPrice);
};

export const dividePriceByCurrency = (price: number, currency: string) =>
  price / CurrencyDivider[currency as keyof typeof CurrencyDivider];

export const multiplyPriceByCurrency = (price: number, currency: string) =>
  Math.round(price * CurrencyDivider[currency as keyof typeof CurrencyDivider]);
