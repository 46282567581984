<template>
  <n-modal v-model:show="loading" :mask-closable="false" :close-on-esc="false">
    <div class="loader"></div>
  </n-modal>
</template>

<style scoped>
.loader {
  border: 16px solid #f3f3f3;
  border-top: 16px solid #0E1D31;
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
<script setup lang="ts">
import { NModal } from "naive-ui";
import type { PropType } from "vue";
import { onMounted, ref } from "vue";

const props = defineProps({
  loader: Boolean as PropType<boolean>,
});
const loading = ref(false);

onMounted(() => {
  loading.value = props.loader || false;
});
</script>
