<template>
  <n-breadcrumb>
    <n-breadcrumb-item @click="router.push({ name: 'dashboard' })">
      <n-icon :component="HomeIcon" />
    </n-breadcrumb-item>
    <n-breadcrumb-item class="page-name" v-if="currentRoute !== 'dashboard'">
      {{ currentRoute }}
    </n-breadcrumb-item>
  </n-breadcrumb>
</template>

<style scoped>
.page-name {
  text-transform: capitalize;
  font-size: 16px;
}
</style>

<script setup lang="ts">
import { NBreadcrumb, NIcon, NBreadcrumbItem } from "naive-ui";
import { HomeOutline as HomeIcon } from "@vicons/ionicons5";
import { computed } from "vue";
import router from "@/router";
import { useRoute } from "vue-router";

const currentRoute = computed(() => {
  return useRoute().name;
});
</script>
