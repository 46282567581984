import { getToken } from "@/utils/token";

const API_KEY = import.meta.env.VITE_API_KEY;

export class Config {
  private token = getToken();

  public getHeaders() {
    return {
      Authorization: `Bearer ${this.token}`,
      "x-api-key": API_KEY,
    };
  }
}
