import { Config } from "@/services/config";
import axios, {
  type AxiosInstance,
  type AxiosRequestConfig,
  type AxiosError,
} from "axios";
import ErrorHandler from "@/handlers/errorHandler";
import { cache, cachedKeys } from "@/utils/cache";
import type {
  CategoryType,
  Item,
  ItemToUpdate,
  Menu,
  MenuToUpdate,
  NewMenu,
  UpdatedCategoryType,
  UpdatedItem,
} from "@/views/menus/catalogs/types/Menu";

const API_HOST = import.meta.env.VITE_API_HOST;

export class MenuService {
  private venueId = cache.getCache(cachedKeys.VENUE_ID);

  private config: AxiosRequestConfig = {
    headers: new Config().getHeaders(),
    baseURL: `${API_HOST}/api/v1/catalogs/menus`,
  };
  private client: AxiosInstance;

  constructor() {
    this.client = axios.create(this.config);
  }

  public async getMenus(): Promise<Menu[] | undefined> {
    try {
      const result = await this.client.get(`/`);
      return result.data.data.menus;
    } catch (e) {
      ErrorHandler.onError(e);
      return;
    }
  }

  public async createMenu(newMenu: NewMenu): Promise<Menu | undefined> {
    try {
      const result = await this.client.post(`/`, newMenu);
      return result.data.data;
    } catch (e) {
      ErrorHandler.onError(e);
      return;
    }
  }

  public async updateMenu(
    updatedMenu: MenuToUpdate
  ): Promise<Menu | undefined> {
    try {
      const result = await this.client.put(`/${updatedMenu.id}`, updatedMenu);
      return result.data.data;
    } catch (e) {
      ErrorHandler.onError(e);
      return;
    }
  }

  public async deleteMenu(menuId: string) {
    try {
      const result = await this.client.delete(`/${menuId}`);
      return result.data;
    } catch (e) {
      ErrorHandler.onError(e);
      return;
    }
  }

  public async syncMenuItems() {
    try {
      await this.client.post(`synchronise`);
      return {
        success: true,
      };
    } catch (e) {
      ErrorHandler.onError(e);
      return {
        success: false,
      };
    }
  }

  public async createCategory(
    menuId: string,
    name: string
  ): Promise<CategoryType | undefined> {
    try {
      const result = await this.client.post(`/categories`, {
        menuId: menuId,
        name: name,
      });
      return result.data.data;
    } catch (e) {
      ErrorHandler.onError(e);
      return;
    }
  }

  public async updateCategory(
    categoryId: string,
    updatedCategory: UpdatedCategoryType
  ): Promise<CategoryType | undefined> {
    try {
      const result = await this.client.put(
        `/categories/${categoryId}`,
        updatedCategory
      );
      return result.data.data;
    } catch (e) {
      ErrorHandler.onError(e);
      return;
    }
  }

  public async deleteCategory(categoryId: string) {
    try {
      const result = await this.client.delete(`/categories/${categoryId}`);
      return result.data;
    } catch (e) {
      ErrorHandler.onError(e);
      return;
    }
  }

  public async getMenuItems(
    page = 1,
    limit: number | undefined,
    name?: string | undefined,
    categoryId?: string | undefined
  ): Promise<
    | { menuItems: Item[]; page: { totalItems: number; totalPages: number } }
    | undefined
  > {
    try {
      const result = await this.client.get(`/items`, {
        params: {
          page,
          limit,
          name,
          categoryId,
        },
      });
      return result.data.data;
    } catch (e) {
      ErrorHandler.onError(e);
      return;
    }
  }

  public async updateMenuItem(
    id: string,
    updatedItem: ItemToUpdate
  ): Promise<
    { success: boolean; item?: UpdatedItem; message?: string } | undefined
  > {
    try {
      const result = await this.client.put(`/items/${id}`, updatedItem);
      return {
        success: true,
        item: result.data.data,
      };
    } catch (e) {
      const error = e as AxiosError;
      if (error.response?.status !== 400) {
        ErrorHandler.onError(e);
        return;
      }
      return {
        success: false,
        message: error.response.data.message,
      };
    }
  }

  public async updateItemOption(
    itemId: string,
    optionGroupId: string,
    dataToUpdate: object
  ) {
    try {
      await this.client.put(
        `/items/${itemId}/groups/${optionGroupId}`,
        dataToUpdate
      );
      return true;
    } catch (e) {
      ErrorHandler.onError(e);
      return;
    }
  }

  public async uploadMenuItemImage(uuid: string, formData: FormData) {
    try {
      const result = await this.client.put(`/items/${uuid}/image`, formData);
      return {
        success: true,
        imageUrl: result.data.data.imageUrl,
      };
    } catch (e) {
      const error = e as AxiosError;
      if (error.response?.status !== 413) {
        ErrorHandler.onError(error);
        return;
      }
      return {
        success: false,
        message: error.response.data.message,
      };
    }
  }

  public async deleteMenuItemImage(uuid: string) {
    try {
      await this.client.delete(`/items/${uuid}/image`);
      return {
        success: true,
      };
    } catch (e) {
      const error = e as AxiosError;
      if (error.response?.status !== 413) {
        ErrorHandler.onError(error);
        return;
      }
      return {
        success: false,
        message: error.response.data.message,
      };
    }
  }
}
