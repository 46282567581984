<template>
  <div>
    <div
      class="d-flex flex-row-reverse flex-wrap justify-space-between px-sm-1 px-md-2 gap-y-2 align-content-start"
      v-if="currency"
    >
      <CreateCustomTreat :currency="currency" />
      <SearchDrink
        v-if="isAdmin"
        :currency="currency"
        :hasDrinks="treats.length > 2"
      />
    </div>
    <div>
      <VTable class="draggable-table">
        <thead>
          <TreatsHead v-if="treats.length" />
        </thead>
        <tbody>
          <TreatRow
            v-for="(treat, index) in treats"
            :key="`${treat.id}-${treat.imageUrl}-${index}-${treat.price.amount}`"
            :treat="treat"
            :index="index"
            :total-treats="treats.length"
            draggable="true"
            @dragstart="dragStart(index)"
            @dragover.prevent
            @drop="drop(index)"
            v-on:orderUpdated="updateTreatOrderByNewIndex"
          />
        </tbody>
      </VTable>
    </div>
  </div>
</template>

<script setup lang="ts">
import CreateCustomTreat from "@/views/menus/drinks/components/CreateCustomTreat.vue";
import type { CustomTreatFromResponse } from "@/views/menus/drinks/types/drinks";
import type { PropType } from "vue";
import TreatsHead from "@/views/menus/drinks/components/TreatsHead.vue";
import TreatRow from "@/views/menus/drinks/components/TreatRow.vue";
import { onUpdated, ref } from "vue";
import { DrinkService } from "@/views/menus/drinks/services/drinkService";
import SearchDrink from "@/views/menus/drinks/components/SearchDrink.vue";
import { useVenueStore } from "@/stores/venueStore";
import { cache, cachedKeys } from "@/utils/cache";
import { VenueUserRole } from "@/views/waiters/types/VenueUser";

const venueStore = useVenueStore();
const drinkService = new DrinkService();

const props = defineProps({
  customTreats: {
    required: true,
    type: Array as PropType<CustomTreatFromResponse[]>,
  },
});
const treats = ref([] as CustomTreatFromResponse[]);
const currency = ref("");
const draggingIndex = ref(0);
const venueUserRole = cache.getCache(cachedKeys.ROLE);
const isAdmin = ref(venueUserRole == VenueUserRole.ADMIN);

const dragStart = (index: number) => {
  draggingIndex.value = index;
};
const drop = async (index: number) => {
  const itemToMove = treats.value.splice(draggingIndex.value, 1)[0];
  treats.value.splice(index, 0, itemToMove);
  await updateTreatsOrder();
};

const updateTreatsOrder = async () => {
  const treatIds = treats.value.map((treat) => treat.id);
  await drinkService.updateTreatOrder(treatIds);
};

const updateTreatOrderByNewIndex = async ({
  previousIndex,
  newIndex,
}: {
  previousIndex: number;
  newIndex: number;
}) => {
  dragStart(previousIndex);
  await drop(newIndex);
};

onUpdated(async () => {
  const venue = await venueStore.getVenue();
  currency.value = venue.currency;
  treats.value = props.customTreats;
});
</script>
