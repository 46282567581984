<template>
  <!-- 👉 Nav header -->
  <div class="nav-header">
    <RouterLink
      to="/"
      class="app-logo d-flex align-center gap-x-3 app-title-wrapper"
    >
      <!-- ℹ️ You can also use img tag or VImg here -->
      <Logo />
    </RouterLink>
  </div>

  <ul>
    <VerticalNavLink
      :item="{
        title: 'Home',
        to: 'dashboard',
        icon: { icon: 'ion-home-outline' },
      }"
    />

    <VerticalNavLink
      :item="{
        title: 'Treating menu',
        to: 'treating menu',
        icon: { icon: 'bxs-drink' },
      }"
    />

    <VerticalNavLink
      :item="{
        title: 'Orders',
        to: 'orders',
        icon: { icon: 'ion-receipt-outline' },
      }"
    />
  </ul>
</template>

<style lang="scss">
// .v-navigation-drawer {
//   height: 100%;

//   .v-navigation-drawer__content {
//     display: flex;
//     flex-direction: column;

//     > ul {
//       flex-grow: 1;
//     }
//   }
// }

.upgrade-banner {
  margin-top: auto;
  // position: absolute;
  // bottom: 13px;
  // left: 50%;
  // transform: translateX(-50%);
}
</style>

<script setup lang="ts">
import { VerticalNavLink, VerticalNavSectionTitle } from "@/@layouts";
import Logo from "@/components/logo.vue";
import { onMounted, ref } from "vue";
import { cache, cachedKeys } from "@/utils/cache";

const orderingEnabled = ref(false);

onMounted(async () => {
  orderingEnabled.value = cache.getCache(cachedKeys.ORDERING_ENABLED);
});
</script>
