import axios, { type AxiosInstance, type AxiosRequestConfig } from "axios";
import ErrorHandler from "@/handlers/errorHandler";
import { Config } from "@/services/config";
import { setRefreshToken, setToken } from "@/utils/token";
import { cache, cachedKeys } from "@/utils/cache";
import { MenuMode, type VenueUser } from "@/types/Venue";

const API_HOST = import.meta.env.VITE_API_HOST;

export class NewVenueService {
  private venueId = cache.getCache(cachedKeys.VENUE_ID);

  private config: AxiosRequestConfig = {
    headers: new Config().getHeaders(),
    baseURL: `${API_HOST}/api/v1/venues`,
  };
  private client: AxiosInstance;

  constructor() {
    this.client = axios.create(this.config);
  }

  public async getVenue() {
    try {
      const result = await this.client.get(`/${this.venueId}`);
      cache.setCache(
        cachedKeys.ORDERING_ENABLED,
        result.data.data.venue.menu_mode === MenuMode.ORDERING ||
          result.data.data.venue.menu_mode === MenuMode.MENU_ONLY
      );
      return result.data.data.venue;
    } catch (e) {
      ErrorHandler.onError(e);
      return;
    }
  }

  public async getAccessibleVenues() {
    try {
      const result = await this.client.get(`/access`);

      return result.data.data.venues;
    } catch (e) {
      ErrorHandler.onError(e);
      return;
    }
  }

  public async updateVenueUsersCurrentVenue(venueId: number) {
    try {
      const result = await this.client.patch(`/users`, { venueId: venueId });

      const token = result.data.data.token;
      const refreshToken = result.data.data.refresh_token;

      setToken(token);
      setRefreshToken(refreshToken);
      window.localStorage.clear();

      const venueUser = await this.getVenueUserData();
      if (!venueUser) return;
      cache.setCache(cachedKeys.VENUE_ID, venueUser.venueId);
      cache.setCache(cachedKeys.ROLE, venueUser.role);
      window.location.reload();
      return;
    } catch (e) {
      ErrorHandler.onError(e);
      return;
    }
  }

  public async getVenueUserData(): Promise<VenueUser | undefined> {
    try {
      const venueUserResponse = await this.client.get(`/users`);

      return venueUserResponse.data.data;
    } catch (e) {
      ErrorHandler.onError(e);
      return;
    }
  }

  public async downloadPaymentsReport(props: {
    fromDate: string;
    toDate: string;
    type: string;
  }) {
    try {
      const response = await this.client.get(
        `/${this.venueId}/payments_report/download`,
        {
          params: {
            fromDate: props.fromDate,
            toDate: props.toDate,
            type: props.type,
          },
          responseType: "blob",
        }
      );
      const url = window.URL.createObjectURL(new Blob([response.data]));
      // Create a temporary link element
      const link = document.createElement("a");
      link.href = url;

      // Set the filename for the download
      link.setAttribute(
        "download",
        `payments_report_${new Date(props.fromDate).toLocaleString(undefined, {
          year: "numeric",
          month: "numeric",
          day: "numeric",
        })}-${new Date(props.toDate).toLocaleString(undefined, {
          year: "numeric",
          month: "numeric",
          day: "numeric",
        })}.${props.type}`
      );

      // Append the link to the body, click it, and remove it
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      // Optional: free up the blob URL memory
      window.URL.revokeObjectURL(url);
    } catch (e) {
      ErrorHandler.onError(e);
      return;
    }
  }
}
