<template>
  <span>
    {{ formattedDate }}
  </span>
</template>

<script setup lang="ts">
import { onMounted, ref } from "vue";

const props = defineProps({
  date: Date,
});
const formattedDate = ref();

const constructDateString = (date: Date | null): string | null => {
  if (!date) return null;

  const dateToHandle = new Date(date);

  const timeFormat = new Intl.DateTimeFormat(navigator.language, {
    hour: "2-digit",
    minute: "2-digit",
  });

  return timeFormat.format(dateToHandle);
};

onMounted(() => {
  if (props.date) formattedDate.value = constructDateString(props.date);
});
</script>
