import { io } from "socket.io-client";
import type { Socket } from "socket.io-client";

const VENUE_SERVICE_URL = import.meta.env.VITE_VENUE_SERVICE_URL;

class WebsocketService {
  private venueId: string;
  private venueURL: string;

  public venueSocket: Socket;

  constructor(venueId: string) {
    this.venueId = venueId;
    this.venueURL = `${VENUE_SERVICE_URL}/venue`;

    this.venueSocket = io(this.venueURL, {
      autoConnect: false,
      transports: ["websocket"],
      upgrade: false,
    });
  }
}

let websocketService: WebsocketService;
export const socket = {
  init: (venueId: string) => {
    websocketService = new WebsocketService(venueId);
    return websocketService;
  },
  get: () => {
    return websocketService;
  },
};

export default socket;
