<template>
  <v-snackbar
    :timeout="3000"
    :color="snackbarStatus"
    class="text-col"
    rounded="pill"
    v-model="showSnackbar"
    location="top"
  >
    {{ snackbarText }}
  </v-snackbar>
  <div v-if="id">
    <v-btn
      color="danger"
      icon="mdi-trash-can-outline"
      variant="text"
      @click="confirmDelete"
    >
    </v-btn>

    <v-dialog v-model="dialog" persistent max-width="400">
      <v-card style="padding: 10px">
        <v-card-title class="text-h5">Confirm Delete</v-card-title>
        <v-card-text>Are you sure you want to delete this item?</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" variant="text" @click="dialog = false"
            >Cancel</v-btn
          >
          <v-btn color="danger" variant="text" @click="deleteItem"
            >Delete</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script setup lang="ts">
import { ref } from "vue";
import { DrinkService } from "@/views/menus/drinks/services/drinkService";
import { useDrinkStore } from "@/views/menus/drinks/stores/drinksStore";

const drinkService = new DrinkService();
const drinkStore = useDrinkStore();

const dialog = ref(false);
const showSnackbar = ref(false);
const snackbarText = ref("");
const snackbarStatus = ref("warning" as "warning" | "error");

const props = defineProps({
  id: {
    type: String,
    required: true,
  },
});
const confirmDelete = () => {
  dialog.value = true;
};

const deleteItem = async () => {
  const response = await drinkService.deleteTreat(props.id);
  if (!response.success) {
    snackbarStatus.value = "error";
    snackbarText.value = "Something went wrong";
    showSnackbar.value = true;
    return;
  }

  await drinkStore.fetchVenueDrinks();
  dialog.value = false;
};
</script>
