import { createApp } from "vue";
import { createPinia } from "pinia";
import piniaPersist from "pinia-plugin-persist";

import App from "./App.vue";
import router from "./router";

import "./assets/main.css";
import ErrorHandler from "@/handlers/errorHandler";
import vuetify from "@/plugins/vuetify";

const app = createApp(App);

ErrorHandler.initSentry(app);

app.config.errorHandler = (error) => ErrorHandler.onError(error);

const pinia = createPinia();
pinia.use(piniaPersist);
app.use(pinia);
app.use(router);
app.use(vuetify);

app.mount("#app");
