<template>
  <span v-if="venueCurrency">
    {{
      formatPrice({
        amount: props.amount,
        currency: venueCurrency,
      })
    }}
  </span>
</template>

<script setup lang="ts">
import { onMounted, ref } from "vue";
import { useVenueStore } from "@/stores/venueStore";
import { formatPrice } from "@/common/Amount";

const props = defineProps({
  amount: Number,
  currency: {
    type: String,
    required: false,
  },
});

const venueCurrency = ref(null as string | null);
const venueStore = useVenueStore();

const constructCurrency = async () => {
  if (props.currency) {
    venueCurrency.value = props.currency;
    return;
  }

  const venue = await venueStore.getVenue();
  venueCurrency.value = venue.currency;
};

onMounted(async () => {
  await constructCurrency();
});
</script>
