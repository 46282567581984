<template>
  <v-snackbar
    :timeout="3000"
    :color="snackbarStatus"
    class="text-col"
    rounded="pill"
    v-model="showSnackbar"
    location="top"
  >
    {{ snackbarText }}
  </v-snackbar>
  <v-btn @click="isActive = true"> Add new item </v-btn>
  <v-dialog
    transition="dialog-top-transition"
    max-width="50rem"
    v-model="isActive"
  >
    <v-card style="border-radius: 24px">
      <v-card-text>
        <div
          class="d-flex flex-column overflow-y-auto gap-y-5 pb-3"
          style="max-width: 50rem"
        >
          <div>
            <div class="d-flex flex-row justify-end" style="z-index: 2">
              <v-btn
                variant="plain"
                icon="mdi-close"
                @click="isActive = false"
              ></v-btn>
            </div>
            <div
              class="d-flex flex-row justify-center pb-2"
              style="margin-top: -1rem"
            >
              <h2>Create new treat</h2>
            </div>
          </div>
          <div
            class="d-flex flex-column flex-wrap justify-space-around gap-x-10 gap-y-5 px-4"
          >
            <div>
              <v-label class="create-custom-treat-label"> Name </v-label>
              <v-text-field
                variant="outlined"
                placeholder="Enter treat name"
                v-model:model-value="form.name"
                class="create-custom-treat-input"
              ></v-text-field>
            </div>
            <div>
              <v-label class="create-custom-treat-label"> Price </v-label>
              <v-text-field
                type="number"
                :suffix="Currencies[currency]"
                placeholder="Enter treat price"
                class="create-custom-treat-input"
                v-model:model-value="form.price.amount"
              ></v-text-field>
            </div>
          </div>
          <div class="d-flex flex-row justify-end px-4">
            <v-btn
              color="primary"
              @click="createCustomTreat"
              width="25%"
              align="end"
              >Add</v-btn
            >
          </div>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<style>
.create-custom-treat-label {
  opacity: 100% !important;
  color: #0e1d31 !important;
  font-weight: bold !important;
}
.create-custom-treat-input ::placeholder {
  opacity: 60%;
  min-width: 15rem;
}
.create-custom-treat-input input {
  color: #0e1d31 !important;
  opacity: 0.9 !important;
}
</style>
<script setup lang="ts">
import { ref, onMounted } from "vue";
import type { NewCustomTreatsInit } from "@/views/menus/drinks/types/customTreats";
import { DrinkService } from "@/views/menus/drinks/services/drinkService";
import { Currencies, CurrencyDivider } from "@/types/Venue";
import { useDrinkStore } from "@/views/menus/drinks/stores/drinksStore";

const drinkStore = useDrinkStore();

const isActive = ref(false);
const showSnackbar = ref(false);
const snackbarText = ref("");
const snackbarStatus = ref("warning" as "warning" | "error");
const form = ref({} as NewCustomTreatsInit);

const props = defineProps({
  currency: {
    type: String,
    required: true,
  },
});
const createCustomTreat = async () => {
  if (!form.value.name || !form.value.price.amount) {
    snackbarText.value = "You have to provide name and price";
    snackbarStatus.value = "warning";
    showSnackbar.value = true;
    return;
  }

  const response = await new DrinkService().createTreat({
    name: form.value.name,
    price: {
      amount: Math.round(
        form.value.price.amount *
          CurrencyDivider[props.currency as keyof typeof CurrencyDivider]
      ),
      currency: props.currency,
    },
  });

  if (!response.success) {
    snackbarText.value = "Something went wrong";
    snackbarStatus.value = "error";
    showSnackbar.value = true;
    return;
  }

  await drinkStore.fetchVenueDrinks();
  isActive.value = false;
  setDefaultFormValue();
};

const setDefaultFormValue = () => {
  form.value = {
    name: "",
    price: { amount: null, currency: props.currency },
  };
};

onMounted(async () => {
  setDefaultFormValue();
});
</script>
