import { Config } from "@/services/config";
import axios, {
  type AxiosInstance,
  type AxiosRequestConfig,
  type AxiosError,
} from "axios";
import ErrorHandler from "@/handlers/errorHandler";
import type {
  BrowseDrinkResponse,
  Extras,
  GetAvailableDrinksResponse,
} from "@/views/menus/drinks/types/drinks";
import type {
  NewCustomTreatsRequest,
  UpdateCustomTreatRequest,
} from "@/views/menus/drinks/types/customTreats";

const API_HOST = import.meta.env.VITE_API_HOST;

export class DrinkService {
  private config: AxiosRequestConfig = {
    headers: new Config().getHeaders(),
    baseURL: `${API_HOST}/api/v1/venues/treats`,
  };
  private client: AxiosInstance;

  constructor() {
    this.client = axios.create(this.config);
  }

  public async browseVenueDrinks(
    name: string,
    currency: string
  ): Promise<BrowseDrinkResponse[]> {
    try {
      const result = await this.client.get(`/`, {
        params: {
          name,
          currency,
        },
      });
      return result.data.data.treats;
    } catch (e) {
      ErrorHandler.onError(e);

      return [];
    }
  }

  public async getAllAvailableDrinks(): Promise<
    GetAvailableDrinksResponse | undefined
  > {
    try {
      const result = await this.client.get(`/drinks`);
      return result.data.data;
    } catch (e) {
      ErrorHandler.onError(e);

      return;
    }
  }

  public async updateDrink(
    uuid: string,
    valuesToBeUpdated: object
  ): Promise<
    | {
        id: string;
        enabled: boolean;
        price: {
          amount: number | null;
          currency: string;
        };
        originalPrice: {
          amount: number | null;
          currency: string;
        };
      }
    | undefined
  > {
    try {
      const result = await this.client.put(
        `/drinks/${uuid}`,
        valuesToBeUpdated
      );
      return result.data.data;
    } catch (e) {
      ErrorHandler.onError(e);
      return;
    }
  }

  public async updateDrinkOption(
    uuid: string,
    valuesToBeUpdated: object
  ): Promise<
    | {
        id: string;
        enabled: boolean;
        price: {
          amount: number | null;
          currency: string;
        };
        originalPrice: {
          amount: number | null;
          currency: string;
        };
      }
    | undefined
  > {
    try {
      const result = await this.client.put(
        `/drinks/options/${uuid}`,
        valuesToBeUpdated
      );
      return result.data.data;
    } catch (e) {
      ErrorHandler.onError(e);
      return;
    }
  }

  public async updateDrinkExtras(uuid: string, extras: Extras) {
    try {
      const result = await this.client.put(`/${uuid}/extras`, extras);
      return result.data;
    } catch (e) {
      ErrorHandler.onError(e);
      return;
    }
  }

  public async uploadDrinkImage(uuid: string, formData: FormData) {
    try {
      const result = await this.client.put(`/drinks/${uuid}/image`, formData);
      return {
        success: true,
        imageUrl: result.data.data.imageUrl,
      };
    } catch (e) {
      const error = e as AxiosError;
      if (error.response?.status !== 413) {
        ErrorHandler.onError(error);
        return;
      }
      return {
        success: false,
        message: error.response?.data.message,
      };
    }
  }

  public createTreat = async (data: NewCustomTreatsRequest) => {
    try {
      await this.client.post(``, data);
      return {
        success: true,
      };
    } catch (e) {
      ErrorHandler.onError(e);
      return {
        success: false,
      };
    }
  };
  public updateTreat = async (id: string, data: UpdateCustomTreatRequest) => {
    try {
      await this.client.put(`/${id}`, data);
      return {
        success: true,
      };
    } catch (e) {
      ErrorHandler.onError(e);
      return {
        success: false,
      };
    }
  };

  public updateTreatOrder = async (treatIds: string[]) => {
    try {
      await this.client.put("/", { venueTreatIds: treatIds });
      return {
        success: true,
      };
    } catch (e) {
      ErrorHandler.onError(e);
      return {
        success: false,
      };
    }
  };

  public deleteTreat = async (id: string) => {
    try {
      await this.client.delete(`/${id}`);
      return {
        success: true,
      };
    } catch (e) {
      ErrorHandler.onError(e);
      return {
        success: false,
      };
    }
  };
}
