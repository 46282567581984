import { Config } from "@/services/config";
import axios, {
  type AxiosInstance,
  type AxiosRequestConfig,
  type AxiosError,
} from "axios";
import ErrorHandler from "@/handlers/errorHandler";
import { cache, cachedKeys } from "@/utils/cache";
import heic2any from "heic2any";

const API_HOST = import.meta.env.VITE_API_HOST;

export class ImagesService {
  private venueId = cache.getCache(cachedKeys.VENUE_ID);

  private config: AxiosRequestConfig = {
    headers: new Config().getHeaders(),
    baseURL: `${API_HOST}/api/v1`,
  };
  private client: AxiosInstance;

  constructor() {
    this.client = axios.create(this.config);
  }

  public async uploadOfferImage(formData: FormData) {
    try {
      const result = await this.client.post(`/venue/offers/image`, formData);
      return result.data.data;
    } catch (e) {
      const error = e as AxiosError;
      if (error.response?.status !== 413) {
        ErrorHandler.onError(error);
        return;
      }
      return error.response?.data;
    }
  }

  public async uploadVenueImage(formData: FormData) {
    try {
      await this.client.post(`/venue/${this.venueId}/image`, formData);
      return true;
    } catch (error) {
      ErrorHandler.onError(error);
      return;
    }
  }

  public async convertHeicToPng(file: Blob) {
    return await heic2any({
      blob: file,
      toType: "image/png",
      quality: 0.5,
    });
  }

  public resizeImage(
    file: File,
    maxWidth: number,
    maxHeight: number
  ): Promise<Blob> {
    return new Promise((resolve, reject) => {
      const image = new Image();
      image.src = URL.createObjectURL(file);
      image.onload = () => {
        const width = image.width;
        const height = image.height;

        if (width <= maxWidth && height <= maxHeight) {
          resolve(file);
        }

        let newWidth;
        let newHeight;

        if (width > height) {
          newHeight = height * (maxWidth / width);
          newWidth = maxWidth;
        } else {
          newWidth = width * (maxHeight / height);
          newHeight = maxHeight;
        }

        const canvas = document.createElement("canvas");
        canvas.width = newWidth;
        canvas.height = newHeight;

        const context = canvas.getContext("2d");

        if (!context) return reject;

        context.drawImage(image, 0, 0, newWidth, newHeight);

        canvas.toBlob(resolve, file.type);
      };
      image.onerror = reject;
    });
  }
}
