<template>
  <div v-if="drinks.length">
    <VTable>
      <thead>
        <DrinkHead :hasSizes="hasSizesField()" />
      </thead>
      <tbody>
        <DrinkRow
          v-for="drink in drinks"
          :key="drink.id"
          :drink="drink"
          v-on:updatedDrink="updateDrink"
        />
        <OptionRow
          v-for="option in displayedOptions"
          :key="option.id"
          :option="option"
          v-on:updatedOption="updateOption"
        />
      </tbody>
    </VTable>
  </div>
</template>

<script setup lang="ts">
import type { PropType } from "vue";
import type { Drink } from "@/views/menus/drinks/types/drinks";
import DrinkRow from "@/views/menus/drinks/components/DrinkRow.vue";
import DrinkHead from "@/views/menus/drinks/components/DrinkHead.vue";
import { onMounted, ref } from "vue";
import { CurrencyDivider } from "@/types/Venue";
import { useDrinkStore } from "@/views/menus/drinks/stores/drinksStore";
import OptionRow from "@/views/menus/drinks/components/OptionRow.vue";
import { Option } from "@/views/menus/drinks/types/drinks";

const props = defineProps({
  drinks: Array as PropType<Drink[]>,
  options: Array as PropType<Option[]>,
});

const displayedDrinks = ref([] as Drink[]);
const displayedOptions = ref([] as Option[]);
const drinkStore = useDrinkStore();
const updateDrink = (drink: Drink) => {
  drinkStore.updateDrinkField(constructDrink(drink));
  drinkStore.fetchVenueDrinks();
};

const constructDrink = (drink: Drink): Drink => ({
  ...drink,
  price: {
    amount: drink.price.amount
      ? drink.price.amount *
        CurrencyDivider[drink.price.currency as keyof typeof CurrencyDivider]
      : null,
    currency: drink.price.currency,
  },
  originalPrice: {
    amount: drink.originalPrice.amount
      ? drink.originalPrice.amount *
        CurrencyDivider[
          drink.originalPrice.currency as keyof typeof CurrencyDivider
        ]
      : null,
    currency: drink.originalPrice.currency,
  },
});

const hasSizesField = () =>
  props.drinks!.some((drink) => drink.sizes.length > 0);

const updateOption = (option: Option) => {
  const indexOfOption = displayedOptions.value
    .map((option) => option.id)
    .indexOf(option.id);

  displayedOptions.value[indexOfOption] = constructOption(option);
};

const constructOption = (option: Option) => ({
  ...option,
  price: {
    amount: option.price.amount
      ? option.price.amount *
        CurrencyDivider[option.price.currency as keyof typeof CurrencyDivider]
      : null,
    currency: option.price.currency,
  },
  originalPrice: {
    amount: option.originalPrice.amount
      ? option.originalPrice.amount *
        CurrencyDivider[
          option.originalPrice.currency as keyof typeof CurrencyDivider
        ]
      : null,
    currency: option.price.currency,
  },
});

onMounted(async () => {
  if (props.drinks) displayedDrinks.value = props.drinks;
  if (props.options) displayedOptions.value = props.options;
});
</script>
