import { defineStore } from "pinia";
import { cachedKeys } from "@/utils/cache";
import { ref } from "vue";
import { MenuService } from "@/views/menus/catalogs/services/menuService";
import type {
  Item,
  ItemParent,
  OptionGroupType,
} from "@/views/menus/catalogs/types/Menu";

export const useItemStore = defineStore(cachedKeys.ITEMS, () => {
  const items = ref({} as Record<string, Item>);
  const categorizedItems = ref([] as Item[]);
  const totalPages = ref(0);

  function getItems() {
    return Object.values(items.value);
  }

  function updateItem(item: Item) {
    items.value[item.id] = item;
  }

  function updateItemParents(itemId: string, parents: ItemParent[]) {
    items.value[itemId].parents = parents;
  }

  function updateGroupGlobally(updatedGroup: OptionGroupType) {
    const itemsWithThisGroup = findAllItemWithThisGroupId(updatedGroup.id);
    updateItemsGroup(itemsWithThisGroup, updatedGroup);
  }

  const findAllItemWithThisGroupId = (groupId: string) => {
    return Object.values(items.value).filter(
      (item) =>
        item.optionGroups.filter((group) => group.id == groupId).length > 0
    );
  };

  const updateItemsGroup = (items: Item[], updatedGroup: OptionGroupType) => {
    items.map((item) => {
      const indexOfGroup = findIndexOfGroup(item, updatedGroup.id);
      item.optionGroups[indexOfGroup] = updatedGroup;
    });
  };

  const findIndexOfGroup = (item: Item, groupId: string) => {
    return item.optionGroups.map((group) => group.id).indexOf(groupId);
  };

  function getTotalPages() {
    return totalPages.value;
  }

  function initStore() {
    new MenuService().getMenuItems(1, 50).then((getItemsResponse) => {
      if (!getItemsResponse) return;

      for (const item of getItemsResponse.menuItems) {
        items.value[item.id] = item;
      }
      totalPages.value = getItemsResponse.page.totalPages;
    });
  }

  async function fetchItems(page = 1, limit = 100, name?: string | undefined) {
    const getItemsResponse = await new MenuService().getMenuItems(
      page,
      limit,
      name
    );
    if (!getItemsResponse) return { success: false };

    items.value = {};
    for (const item of getItemsResponse.menuItems) {
      items.value[item.id] = item;
    }
    totalPages.value = getItemsResponse.page.totalPages;

    return { success: true };
  }

  async function fetchAllItemsByCategoryId(categoryId: string) {
    const getItemsResponse = await new MenuService().getMenuItems(
      1,
      undefined,
      undefined,
      categoryId
    );
    if (!getItemsResponse) return;
    categorizedItems.value = getItemsResponse.menuItems;
  }

  function getCategorizedItems() {
    return categorizedItems.value;
  }

  function removeItemFromCategorizedItems(itemId: string) {
    const indexOfItem = categorizedItems.value
      .map((item) => item.id)
      .indexOf(itemId);
    categorizedItems.value.splice(indexOfItem, 1);
  }

  function addItemToCategorizedItems(itemId: string) {
    const item = items.value[itemId];
    categorizedItems.value.unshift(item);
  }

  function updateCategorizedItem(item: Item) {
    const indexOfItem = categorizedItems.value
      .map((item) => item.id)
      .indexOf(item.id);
    categorizedItems.value[indexOfItem] = item;
  }

  initStore();

  return {
    getItems,
    getTotalPages,
    updateItem,
    updateItemParents,
    updateGroupGlobally,
    fetchItems,
    fetchAllItemsByCategoryId,
    getCategorizedItems,
    removeItemFromCategorizedItems,
    addItemToCategorizedItems,
    updateCategorizedItem,
    initStore,
  };
});
