<template>
  <tr @click="openEditModal" v-if="editOption !== {}">
    <td>{{ editOption.name }}</td>
    <td>
      <n-image width="50" class="py-2" :src="editOption.imageUrl" />
    </td>
    <td>{{ editOption.name }}</td>
    <td>
      <n-input-number
        v-if="editOption.price"
        clearable
        :show-button="false"
        v-model:value="editOption.price.amount"
        placeholder="New price"
        class="price_input"
        @update:value="updateOptionsPrice"
      >
        <template #suffix>
          {{ Currencies[editOption.price.currency] }}
        </template>
      </n-input-number>
    </td>
    <td>
      <n-checkbox
        v-model:checked="editOption.enabled"
        @update:checked="updateOptionsAvailability"
      />
    </td>
  </tr>
</template>

<style scoped>
td {
  text-align: center;
}
.price_input {
  min-width: 150px;
}
</style>

<script setup lang="ts">
import { NImage, NCheckbox, NInputNumber, useNotification } from "naive-ui";
import type { PropType } from "vue";
import type { Option } from "@/views/menus/drinks/types/drinks";
import { CurrencyDivider, Currencies } from "@/types/Venue";
import { onMounted, ref } from "vue";
import { DrinkService } from "@/views/menus/drinks/services/drinkService";

const props = defineProps({
  option: Object as PropType<Option>,
});
const emits = defineEmits(["updatedOption"]);

const showModal = ref(false);
const editOption = ref({} as Option);
const drinkService = new DrinkService();
const notification = useNotification();

const openEditModal = () => {
  showModal.value = true;
};

const updateOptionsPrice = async (price: number | null) => {
  const objectToSent = {
    price: price
      ? price *
        CurrencyDivider[
          editOption.value.price.currency as keyof typeof CurrencyDivider
        ]
      : null,
  };
  const updatedOption = await drinkService.updateDrinkOption(
    editOption.value.id,
    objectToSent
  );

  if (!updatedOption) {
    notification.error({
      content: "Something went wrong updating option's data",
      duration: 3000,
    });
    return;
  }

  setEditOption({
    ...editOption.value,
    ...updatedOption,
  });
  emits("updatedOption", editOption.value);
};

const updateOptionsOriginalPrice = async (price: number | null) => {
  const objectToSent = {
    originalPrice: price
      ? price *
        CurrencyDivider[
          editOption.value.originalPrice
            .currency as keyof typeof CurrencyDivider
        ]
      : null,
  };
  const updatedOption = await drinkService.updateDrinkOption(
    editOption.value.id,
    objectToSent
  );

  if (!updatedOption) {
    notification.error({
      content: "Something went wrong updating option's data",
      duration: 3000,
    });
    return;
  }

  setEditOption({
    ...editOption.value,
    ...updatedOption,
  });
  emits("updatedOption", editOption.value);
};

const updateOptionsAvailability = async (value: boolean) => {
  const objectToSent = {
    enabled: value,
  };
  const updatedOption = await drinkService.updateDrinkOption(
    editOption.value.id,
    objectToSent
  );

  if (!updatedOption) {
    notification.error({
      content: "Something went wrong updating option's data",
      duration: 3000,
    });
    return;
  }

  setEditOption({
    ...editOption.value,
    ...updatedOption,
  });
  emits("updatedOption", editOption.value);
};

const setEditOption = (updatedOption: Option) => {
  if (updatedOption == ({} as Option)) return;
  editOption.value = {
    enabled: updatedOption.enabled,
    id: updatedOption.id,
    name: updatedOption.name,
    imageUrl: updatedOption.imageUrl,
    price: {
      amount: updatedOption.price?.amount
        ? updatedOption.price.amount /
          CurrencyDivider[
            updatedOption.price.currency as keyof typeof CurrencyDivider
          ]
        : null,
      currency: updatedOption.price.currency,
    },
    originalPrice: {
      amount: updatedOption.originalPrice?.amount
        ? updatedOption.originalPrice.amount /
          CurrencyDivider[
            updatedOption.originalPrice.currency as keyof typeof CurrencyDivider
          ]
        : null,
      currency: updatedOption.price.currency,
    },
  };
};

onMounted(() => {
  if (props?.option && props.option !== ({} as Option))
    setEditOption(props.option);
});
</script>
