import type { User } from "@/types/Messaging";
import type { Offer } from "@/views/offers/types/Offers";
import type { Menu } from "@/views/menus/types/Menu";
import type { Table } from "@/views/tables/types/Tables";
import type { VenueUserRole } from "@/views/waiters/types/VenueUser";
import type { OfferType } from "@/views/offers/types/Offers";
import type { Amount } from "@/common/Amount";

export type WaiterCall = {
  id: string;
  checkIn: OrderedOfferCheckIn;
  createdAt: string;
  user_socket_id: string;
  type: "payment" | "default";
  acknowledged: Date;
  acknowledgedBy: {
    id: string;
    displayName: string | undefined;
  };
  cancelled: boolean;
};

export type VenueUser = {
  displayName: string;
  email: string;
  id: string;
  imageUrl: string | null;
  role: VenueUserRole;
  venueId: string;
  waiterCode: string | null;
};

export type Days =
  | "monday"
  | "tuesday"
  | "wednesday"
  | "thursday"
  | "friday"
  | "saturday"
  | "sunday";

export type OpeningHours = {
  monday: string | null;
  tuesday: string | null;
  wednesday: string | null;
  thursday: string | null;
  friday: string | null;
  saturday: string | null;
  sunday: string | null;
};

export type VenueType = {
  id: string;
  username: string;
  venue_name: string;
  description: string;
  welcome_message: string;
  venue_type: number;
  cuisine: number;
  address: string;
  phone_number: string;
  coordinates: {
    longitude: number;
    latitude: number;
  };
  price_category: number;
  menu_pdf_url: string;
  menus: Menu[];
  image_url: string;
  opening_hours: OpeningHours;
  social: string;
  pos_integrated: boolean;
  currency: string;
  menu_mode: MenuMode;
};

export enum MenuMode {
  PDF = "PDF",
  MENU_ONLY = "MENU_ONLY",
  ORDERING = "ORDERING",
}

export interface CustomerAnalytics {
  activeUsersCount: number;
  totalCheckIns: number;
  latestCheckIns: CustomerAnalyticsChart[];
}

export interface TreatAnalytics {
  totalTreats: number;
  totalTreatsToday: number;
  totalRevenue: Amount;
  totalRevenueToday: Amount;
  orderedTreats: AnalyticsChart[];
}

export interface CustomerAnalyticsChart {
  checkedInAt: Date;
}

export interface AnalyticsChart {
  createdAt: Date;
}
export interface TreatMetric {
  treatName: string;
  totalRevenue: Amount;
  timesOrdered: number;
}

export type DownloadStatisticUser = {
  data: {
    id: string;
    displayName: string;
    imageUrl: string | null;
  };
  downloads: number;
};

export type OrderedTreatAnalytics = {
  count: number;
  totalValue: number;
  totalCommission: number;
};

export type OrderedOffersAnalytics = {
  count: number;
  totalValue: number;
  totalCommission: number;
};

export type UserCheckIn = {
  id: string;
  checked_in_at: string;
  checked_out_at: string;
  venue: string;
  qr_code: string;
  user: string;
};

type OrderedOfferCheckIn = {
  id: string;
  qr_code: Table;
  user: User;
};

export type OfferOrder = {
  id: string;
  offer: Offer;
  checkIn: OrderedOfferCheckIn;
  quantity: string;
  acknowledged: boolean;
  acknowledgedAt: Date | null;
  acknowledgedBy: {
    id: string;
    displayName: string | undefined;
  } | null;
  dismissed: boolean;
  dismissedAt: Date | null;
  dismissedBy: {
    id: string;
    displayName: string;
  } | null;
  createdAt: Date;
  unitPrice: number | undefined;
  totalPrice: number | undefined;
  currency: string;
};

type OrderedTreatCheckIn = {
  id: string;
  qrcode: Table | null;
  user: User;
};

export type TreatOrder = {
  id: string;
  price: number;
  currency: string;
  offer: Offer;
  receiverUserCheckIn: OrderedTreatCheckIn;
  senderUserCheckIn: OrderedTreatCheckIn | null;
  quantity: string;
  description: string;
  acknowledged: boolean;
  acknowledgedAt: Date | null;
  acknowledgedBy: {
    id: string;
    displayName: string | undefined;
  } | null;
  createdAt: Date;
  updatedAt: string;
};

export type MixedOrder = {
  id: string;
  table: string | null;
  type: OrderType | OfferType;
  user: string;
  name: string;
  price: number | null | undefined;
  time: Date;
  acknowledged: boolean;
  acknowledgedAt: Date | null;
  acknowledgedBy: {
    id: string;
    displayName: string | undefined;
  } | null;
  dismissed?: boolean;
  dismissedAt?: Date | null;
  dismissedBy?: {
    id: string;
    displayName: string;
  } | null;
  currency: string;
};

export enum OrderType {
  TREAT = "TREAT",
  OFFER = "OFFER",
  SUGGESTION = "SUGGESTION",
}
export type getCustomerQuery = {
  tableId?: string;
  dietary_preferences?: string;
  sort?: string;
  order?: string;
};

export const CuisineType = [
  "American",
  "Chinese",
  "French",
  "Greek",
  "Indian",
  "Italian",
  "Japanese",
  "Korean",
  "Lebanese",
  "Mexican",
  "Spanish",
  "Thai",
  "N/A",
];

export const VenueTypes = [
  "Bar",
  "Beach Club",
  "Bistro",
  "Cafe",
  "Casual Dining",
  "Coffee Shop",
  "Fast Food",
  "Fine Dining",
  "Lounge",
  "Night Club",
  "Pub",
  "Sports Bar",
  "Hotel",
];

export const PriceCategories = ["$", "$$", "$$$", "$$$$"];

export enum Currencies {
  "EUR" = "€",
  "eur" = "€",
  "USD" = "$",
  "usd" = "$",
  "GBP" = "£",
  "gbp" = "£",
  "AED" = "AED",
  "aed" = "AED",
}

export enum CurrencyDivider {
  "EUR" = 100,
  "eur" = 100,
  "USD" = 100,
  "usd" = 100,
  "GBP" = 100,
  "gbp" = 100,
  "AED" = 100,
  "aed" = 100,
}
