<template>
  <VAvatar style="cursor: pointer" color="primary" variant="tonal">
    <VImg v-if="user.imageUrl" :src="user.imageUrl" />
    <VIcon v-else size="24" icon="mdi-account-outline" />

    <VMenu activator="parent" width="230" location="bottom end" offset="14px">
      <VList>
        <VListItem>
          <template #prepend>
            <VListItemAction start>
              <VAvatar color="primary" size="40" variant="tonal">
                <VImg v-if="user.imageUrl" :src="user.imageUrl" />
                <VIcon v-else size="24" icon="mdi-account-outline" />
              </VAvatar>
            </VListItemAction>
          </template>

          <VListItemTitle class="font-weight-semibold">
            {{ user.displayName }}
          </VListItemTitle>
          <VListItemSubtitle class="text-disabled">
            {{ user.role }}
          </VListItemSubtitle>
        </VListItem>

        <VDivider class="my-2" />

        <VListItem @click="logout">
          <template #prepend>
            <VIcon class="me-2" icon="mdi-logout-variant" size="22" />
          </template>

          <VListItemTitle>Logout</VListItemTitle>
        </VListItem>
      </VList>
    </VMenu>
  </VAvatar>
</template>

<style scoped>
@import "@/styling/global.css";
</style>

<script setup lang="ts">
import { deleteRefreshToken, deleteToken } from "@/utils/token";
import { onMounted, ref } from "vue";
import type { VenueUser } from "@/types/Venue";
import { NewVenueService } from "@/services/newVenueService";

const user = ref({} as VenueUser);
function logout() {
  deleteToken();
  deleteRefreshToken();
  window.location.reload();
  return;
}

const getUsersInfo = async () => {
  const venueUser = await new NewVenueService().getVenueUserData();
  if (!venueUser) return;
  user.value = venueUser;
};

onMounted(async () => {
  await getUsersInfo();
});
</script>
