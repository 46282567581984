export function previousMonthFirstDay() {
  const d = new Date();
  d.setMonth(d.getMonth() - 1);
  d.setDate(0);
  d.setHours(24);
  d.setMinutes(0);
  return +d;
}

export function previousMonthLastDay() {
  const d = new Date();

  d.setMonth(d.getMonth() - 1);
  d.setDate(new Date(d.getFullYear(), d.getMonth() + 1, 0).getDate() - 1);
  d.setHours(24);
  d.setMinutes(0);
  return +d;
}

export function currentMonth() {
  return +new Date();
}

export function getLastMondayDate() {
  const currentDate = new Date();
  currentDate.setDate(currentDate.getDate() - ((currentDate.getDay() + 6) % 7));
  currentDate.setHours(0, 0, 0);
  return currentDate;
}

export function getStartOfTheDay() {
  const d = new Date();
  d.setHours(0, 0, 0, 0);
  return d;
}

export function currentMonthFirstDay() {
  const d = new Date();
  d.setDate(0);
  d.setHours(24);
  d.setMinutes(0);
  return +d;
}
