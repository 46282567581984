<template>
  <v-menu>
    <template v-slot:activator="{ props }">
      <VTextField
        v-bind="props"
        v-model="searchText"
        placeholder="Add from an existing item"
        style="max-width: 25rem"
        prepend-inner-icon="mdi-content-copy"
        :append-inner-icon="searchText.length ? 'mdi-close-circle' : ''"
        @click:append-inner="searchText = ''"
        @update:modelValue="handleNameSearch"
        @focus="showModal = treats?.length > 0"
      />
    </template>

    <VList
      v-show="treats?.length && searchText.length > 2 && showModal"
      class="search_drinks"
    >
      <VListItem
        v-for="treat in treats"
        :key="treat.name"
        @click="createNewTreat(treat)"
        class="searched_drink"
      >
        <div
          class="d-flex flex-row gap-x-3 gap-y-1 align-center cursor-pointer"
        >
          <div>
            <v-img
              :src="treat.imageUrl!"
              style="border-radius: 12px; min-width: 40px"
            />
          </div>
          <div style="width: 180px">
            <VListItemTitle>{{ treat.name }}</VListItemTitle>
          </div>
          <div>
            <VListItemSubtitle>
              <PriceFormatter
                :amount="treat.price.amount"
                :currency="treat.price.currency"
              />
            </VListItemSubtitle>
          </div>
        </div>
      </VListItem>
    </VList>

    <VList
      v-if="treats?.length === 0 && searchText.length > 2"
      class="search_drinks"
      :class="{
        search_drinks_absolute: hasDrinks,
        search_drinks_relative: !hasDrinks,
      }"
    >
      <div class="no_items_found">
        <v-icon icon="mdi-alert-circle"></v-icon> No items found
      </div>
    </VList>
  </v-menu>
</template>

<style scoped>
.search ::placeholder {
  opacity: 0.8 !important;
}
.search_drinks {
  z-index: 1;
  left: 0;
  border-radius: 16px !important;
  margin-top: 10px;
  border: 1px solid rgba(210, 210, 215, 0.4);
  box-shadow: 0 2px 5px 5px #0000001a;
  max-height: 300px;
  overflow-y: auto;
  padding: 0.5rem;
}
.search_drinks::-webkit-scrollbar {
  width: 4px;
}
.search_drinks::-webkit-scrollbar-track {
  width: 6px;
}
.search_drinks::-webkit-scrollbar-thumb {
  background: transparent;
}
.searched_drink {
  border-radius: 16px;
  min-width: 380px;
  padding: 0.5rem;
}
.no_items_found {
  text-align: center;
  color: #757575;
  font-style: italic;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  padding: 1rem;
  gap: 0.5rem;
}
.no_items_found v-icon {
  margin-right: 0.5rem;
}
</style>

<script setup lang="ts">
import PriceFormatter from "@/components/PriceFormatter.vue";
import { ref } from "vue";
import { DrinkService } from "@/views/menus/drinks/services/drinkService";
import type { BrowseDrinkResponse } from "@/views/menus/drinks/types/drinks";
import { useDrinkStore } from "@/views/menus/drinks/stores/drinksStore";

const props = defineProps({
  currency: {
    type: String,
    required: true,
  },
  hasDrinks: {
    type: Boolean,
    required: true,
  },
});
const drinkStore = useDrinkStore();
const drinkService = new DrinkService();

let timeout = undefined as number | undefined;
const searchText = ref("");
const treats = ref(null as BrowseDrinkResponse[] | null);
const showModal = ref(false);

const handleNameSearch = async (newValue: string) => {
  if (newValue.length < 3) return;

  clearTimeout(timeout);

  timeout = setTimeout(async () => {
    treats.value = await drinkService.browseVenueDrinks(
      searchText.value,
      props.currency
    );
    showModal.value = treats.value.length > 0;
  }, 500);
};

const createNewTreat = async (treat: BrowseDrinkResponse) => {
  await drinkService.createTreat({
    name: treat.name,
    price: treat.price,
    type: treat.type,
    imageUrl: treat.imageUrl || undefined,
  });
  await drinkStore.fetchVenueDrinks();
  searchText.value = "";
};
</script>
