<template>
  <n-config-provider :theme-overrides="themeOverrides">
    <n-loading-bar-provider>
      <n-notification-provider>
        <n-message-provider>
          <v-snackbar
            v-model="showSnackbar"
            location="top"
            color="warning"
            rounded="pill"
            :timeout="-1"
          >
            <div style="text-align: center">You are currently offline.</div>
          </v-snackbar>
          <RouterView />
        </n-message-provider>
      </n-notification-provider>
    </n-loading-bar-provider>
  </n-config-provider>
</template>

<style>
.dion-menu-option-hide-checkmark .n-base-select-option__check {
  display: none;
}
.n-tag__border {
  border-radius: 15px !important;
  background-color: #1f80f01f;
}

.conversation-list-item-label {
  font-weight: bold;
  margin-left: 5px;
  padding-top: 25px;
}
.conversation-list-item-message {
  position: relative;
  margin-left: 5px;
  display: block;
  padding-bottom: 5px;
}

.dion-conversation-list .n-menu-item {
  margin-bottom: 5px;
  height: 60px;
}
.dion-conversation-list .n-menu-item.unread::after {
  position: absolute;
  top: 10px;
  right: 15px;
  height: 10px;
  width: 10px;
  content: "";
  background-color: red;
  border-radius: 50%;
}

.dion-modal {
  background-color: white;
  border-radius: 25px;
}
.dion-dialog {
  border-radius: 25px;
}
.n-avatar {
  --n-border: 1px solid #000 !important;
}
.create-menu-image-select > div > div {
  width: 350px;
  height: 286px !important;
}
.edit-menu-image-select > div > div {
  width: 220px;
  height: 180px !important;
}
.edit-menu-image-select > div {
  border-radius: 10px;
}

.n-base-clear__clear {
  color: #008cad !important;
}
.n-base-suffix__arrow {
  color: #008cad !important;
}

.option-group-description-input > div > div > input {
  font-weight: bolder;
}
.option-group-description-input {
  border-radius: 10px !important;
}
.edit-menu-option {
  border-radius: 10px !important;
}
.edit-menu-type-select > div {
  border-radius: 10px !important;
}
.create-menu-type-select > div {
  border-radius: 10px !important;
}
.create-menu-name-input > div {
  border-radius: 10px !important;
}
.create-menu-image-select > div {
  border-radius: 10px;
}
.option-name-input {
  border-radius: 10px !important;
}
.email-login-input > div {
  border-radius: 10px !important;
}
.password-login-input > div {
  border-radius: 10px !important;
}
.n-date-panel-actions > div > button {
  color: white;
}
.n-popconfirm__action > button {
  color: white;
}
.n-tabs-tab--active {
  background-color: white !important;
}
</style>

<script setup lang="ts">
import {
  NNotificationProvider,
  NMessageProvider,
  NLoadingBarProvider,
  NConfigProvider,
} from "naive-ui";
import type { GlobalThemeOverrides } from "naive-ui";
import { useConnection } from "@/common/useConnection";
import { ref, watch } from "vue";

const themeOverrides: GlobalThemeOverrides = {
  common: {
    primaryColor: "#008CAD",
    textColor1: "#000000",
    baseColor: "#FFFFFF",
    actionColor: "#FFFFFF",
  },
  Button: {
    color: "#008CAD",
    textColor: "#FFFFFF",
    colorHover: "#0E1D31",
    textColorHover: "#FFFFFF",
    colorPressed: "#0E1D31",
    textColorPressed: "#FFFFFF",
    borderRadiusMedium: "10px",
    borderHover: "#ffffff",
    borderPressed: "#ffffff",
    borderFocus: "#ffffff",
  },
  Menu: {
    itemColorHover: "#0e1d31",
    itemColorActive: "#0e1d31",
    itemIconColor: "#0e1d31",
    itemColorActiveHover: "#0e1d31",
    itemTextColorActiveHover: "#FFFFFF",
    itemColorActiveCollapsed: "#0e1d31",
    itemIconColorActive: "#FFFFFF",
    itemIconColorActiveHover: "#FFFFFF",
    itemTextColorActive: "#FFFFFF",
    itemTextColorHover: "#FFFFFF",
    itemIconColorHover: "#FFFFFF",
    itemIconColorChildActiveHover: "#FFFFFF",
    itemTextColorChildActiveHover: "#FFFFFF",
    itemTextColorChildActive: "#0e1d31",
    itemIconColorChildActive: "#0e1d31",
    arrowColorChildActive: "#0e1d31",
    arrowColorChildActiveHover: "#FFFFFF",
    arrowColorHover: "#FFFFFF",
  },
  Switch: {
    railColor: "#008CAD",
    railColorActive: "#4A2F48",
  },
  Anchor: {
    linkTextColor: "#0E1D31",
    linkTextColorHover: "#008CAD",
  },
  Tag: {
    colorPrimary: "#008CAD",
  },
  LoadingBar: {
    height: "3px",
  },
};

const { online } = useConnection();
const showSnackbar = ref(false);

watch(online, (newValue: boolean) => {
  showSnackbar.value = !newValue;
});
</script>
