import axios, { type AxiosInstance, type AxiosRequestConfig } from "axios";
import ErrorHandler from "@/handlers/errorHandler";
import type { OfferOrder, TreatOrder } from "@/types/Venue";
import { Config } from "@/services/config";
const API_HOST = import.meta.env.VITE_API_HOST;

export class OrderService {
  private config: AxiosRequestConfig = {
    headers: new Config().getHeaders(),
    baseURL: `${API_HOST}/api/v1`,
  };
  private client: AxiosInstance;

  constructor() {
    this.client = axios.create(this.config);
  }

  public async getOrderedOffers(params?: {
    fromDate: string | null;
    toDate: string | null;
  }): Promise<OfferOrder[] | undefined> {
    try {
      const result = await this.client.get(`/order/offers`, {
        params,
      });
      return result.data.data.orderedOffers;
    } catch (error) {
      ErrorHandler.onError(error);
      return;
    }
  }

  public async acknowledgeOrderOffers(acknowledged_ids: string[]) {
    try {
      await this.client.post(`/order/offers`, {
        acknowledged_ids,
      });
      return true;
    } catch (error) {
      ErrorHandler.onError(error);
      return false;
    }
  }

  public async dismissOrderedOffers(dismissedIds: string[]) {
    try {
      await this.client.delete(`/order/offers`, {
        data: {
          dismissedIds,
        },
      });
      return true;
    } catch (error) {
      ErrorHandler.onError(error);
      return false;
    }
  }

  public async getOrderedTreats(params?: {
    fromDate: string | null;
    toDate: string | null;
  }): Promise<TreatOrder[] | undefined> {
    try {
      const result = await this.client.get(`/order/treats`, {
        params,
      });
      return result.data.data.orderedTreats;
    } catch (error) {
      ErrorHandler.onError(error);
      return;
    }
  }

  public async acknowledgeOrderedTreats(
    acknowledgedIds: string[]
  ): Promise<boolean> {
    try {
      await this.client.post(`/order/treats`, {
        acknowledgedIds,
      });
      return true;
    } catch (error) {
      ErrorHandler.onError(error);
      return false;
    }
  }

  public async getOrders(fromDate: string | null, toDate: string | null) {
    try {
      const result = await this.client.get(`/admin/venue/orders`, {
        params: {
          fromDate: fromDate,
          toDate: toDate,
        },
      });
      return {
        tables: result.data.data.tables,
        total_money: result.data.data.total_money,
        total_tips: result.data.data.total_tips,
      };
    } catch (error) {
      ErrorHandler.onError(error);
      return;
    }
  }
}
