<template>
  <div class="container">
    <div class="logo">
      <img src="/dion-logo.svg" alt="dion-logo" />
    </div>
    <div class="login">
      <n-form ref="formRef" :model="form" size="small" @submit="login">
        <n-space
          vertical
          title="Please enter your credentials"
          style="width: 200px"
        >
          <n-input
            type="text"
            v-model:value="form.email"
            placeholder="Email"
            required
            autocomplete="on"
            class="email-login-input"
          />
          <n-input
            ref="input"
            type="password"
            placeholder="Password"
            v-model:value="form.password"
            required
            autocomplete="on"
            @keyup.enter="login"
            class="password-login-input"
          />
          <div>
            <VBtn color="darkPrimary" v-on:click="login" class="login-button">
              Login
            </VBtn>
          </div>
        </n-space>
      </n-form>
    </div>
  </div>
</template>
<style scoped>
.container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
}
.logo {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 15px;
}
.logo > img {
  height: 200px;
}
@media only screen and (max-width: 900px) {
  .logo > img {
    height: 100px;
  }
}
.login {
  margin: 0 auto;
}
.login-button {
  width: 100%;
  /*border-radius: 10px;*/
}
</style>
<script setup lang="ts">
import { ref } from "vue";
import {
  NForm,
  NInput,
  NButton,
  NSpace,
  useMessage,
  useLoadingBar,
} from "naive-ui";
import type { FormInst } from "naive-ui";
import { cache, cachedKeys } from "@/utils/cache";
import router from "@/router";
import { AuthService } from "@/services/authService";

const formRef = ref<FormInst | null>(null);
const form = ref({
  email: "",
  password: "",
});
const message = useMessage();
const loadingBar = useLoadingBar();

async function login() {
  if (form.value.email !== "" && form.value.password !== "") {
    const userCredentials = {
      email: form.value.email,
      password: form.value.password,
    };

    loadingBar.start();

    try {
      await new AuthService().login(userCredentials);
      loadingBar.finish();

      if (
        cache.getCache(cachedKeys.LAST_VISITED_ROUTE) !== undefined &&
        cache.getCache(cachedKeys.LAST_VISITED_ROUTE) !== ""
      ) {
        await router.push({
          name: cache.getCache(cachedKeys.LAST_VISITED_ROUTE),
        });
        cache.setCache(cachedKeys.LAST_VISITED_ROUTE, "");
        return;
      }
      await router.push({ name: "dashboard" });
    } catch (error) {
      loadingBar.error();
      message.error("Wrong credentials", { duration: 2000 });
      form.value.password = "";
    }
  } else {
    message.warning("Please enter your email and password", { duration: 2000 });
  }
}
</script>
