<template>
  <VRow
    justify="start"
    align="start"
    class="overflow-x-auto gap-x-10 gap-y-2 my-2 px-3"
  >
    <div v-for="item in statistics" :key="`${item.title}-${item.stats}`">
      <div class="d-flex align-start w-100">
        <div class="me-3">
          <VAvatar :color="'primary'" rounded size="42" class="elevation-1">
            <VIcon size="24" :icon="item.icon" />
          </VAvatar>
        </div>
        <div>
          <div class="text-caption font-weight-bold">
            {{ item.title }}
          </div>
          <span class="text-h6 font-weight-medium" style="width: fit-content">
            <span v-if="item.isPrice">
              <PriceFormatter :amount="item.stats" />
            </span>
            <span v-else>
              {{ item.stats }}
            </span>
          </span>
        </div>
      </div>
    </div>
  </VRow>
</template>

<script setup lang="ts">
import type { PropType } from "vue";
import type {
  OrderAnalytics,
  OrderedOffersAnalytics,
  OrderedSuggestionsAnalytics,
  OrderedTreatAnalytics,
} from "@/views/orders/types";
import { onMounted, ref, watch } from "vue";
import PriceFormatter from "@/components/PriceFormatter.vue";

const props = defineProps({
  orderedOffers: Object as PropType<OrderedOffersAnalytics>,
  orderedSuggestions: Object as PropType<OrderedSuggestionsAnalytics>,
  orderedTreats: Object as PropType<OrderedTreatAnalytics>,
  totalOrders: Object as PropType<OrderAnalytics>,
});

const statistics = ref(
  [] as { title: string; stats: number; icon: string; isPrice: boolean }[]
);

const constructStatistics = () => {
  statistics.value = [
    {
      title: "Total orders",
      stats: props.orderedTreats!.count,
      icon: "fluent:service-bell-16-filled",
      isPrice: false,
    },
    {
      title: "Total revenue",
      stats: props.orderedTreats!.totalValue,
      icon: "mdi-currency-usd",
      isPrice: true,
    },
  ];
};

const checkProps = (): boolean =>
  props.orderedTreats !== undefined &&
  props.orderedOffers !== undefined &&
  props.totalOrders !== undefined;

watch(
  () => props.totalOrders,
  () => {
    if (checkProps()) constructStatistics();
  }
);

onMounted(() => {
  if (checkProps()) constructStatistics();
});
</script>
