import axios, { type AxiosInstance, type AxiosRequestConfig } from "axios";
import type {
  CustomerAnalytics,
  TreatAnalytics,
  TreatMetric,
} from "@/types/Venue";
import ErrorHandler from "@/handlers/errorHandler";
import { Config } from "@/services/config";

const API_HOST = import.meta.env.VITE_API_HOST;

export class AnalyticService {
  private config: AxiosRequestConfig = {
    headers: new Config().getHeaders(),
    baseURL: `${API_HOST}/api/v1`,
  };
  private client: AxiosInstance;

  constructor() {
    this.client = axios.create(this.config);
  }

  public async getCustomerAnalytics(
    venueId: number
  ): Promise<CustomerAnalytics | null> {
    try {
      const result = await this.client.get(
        `/venues/${venueId}/customers/analytics`
      );
      return result.data.data.customerAnalytics;
    } catch (e) {
      ErrorHandler.onError(e);
      return null;
    }
  }

  public async getTreatAnalytics(
    venueId: number
  ): Promise<TreatAnalytics | null> {
    try {
      const result = await this.client.get(
        `/venues/${venueId}/treats/analytics`
      );
      return result.data.data.treatAnalytics;
    } catch (e) {
      ErrorHandler.onError(e);
      return null;
    }
  }

  public async getTreatMetrics(venueId: number): Promise<TreatMetric[] | null> {
    try {
      const result = await this.client.get(`/venues/${venueId}/treats/revenue`);
      return result.data.data.treatMetrics;
    } catch (e) {
      ErrorHandler.onError(e);
      return null;
    }
  }
}
