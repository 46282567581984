import axios, {
  type AxiosInstance,
  type AxiosRequestConfig,
  type AxiosError,
} from "axios";
import ErrorHandler from "@/handlers/errorHandler";
import { Config } from "@/services/config";
import type { VenueUser } from "@/views/waiters/types/VenueUser";
import type { VenueUserRole } from "@/views/waiters/types/VenueUser";
const API_HOST = import.meta.env.VITE_API_HOST;

export class VenueUserService {
  private config: AxiosRequestConfig = {
    headers: new Config().getHeaders(),
    baseURL: `${API_HOST}/api/v1/venue/users`,
  };
  private client: AxiosInstance;

  constructor() {
    this.client = axios.create(this.config);
  }

  public async createVenueUser(
    email: string,
    password: string,
    role: VenueUserRole,
    displayName: string,
    waiterCode: string
  ) {
    try {
      const result = await this.client.post(``, {
        email,
        password,
        role,
        displayName,
        waiterCode,
      });
      return {
        success: true,
        user: result.data.data.user,
      };
    } catch (e) {
      const error = e as AxiosError;

      if (error.response?.status == 409) {
        return {
          success: false,
          message: error.response.data.message,
        };
      }

      ErrorHandler.onError(e);
      return;
    }
  }

  public async getAllVenueUsers(): Promise<VenueUser[] | undefined> {
    try {
      const result = await this.client.get(``);
      return result.data.data.users;
    } catch (e) {
      ErrorHandler.onError(e);
      return;
    }
  }

  public async updateVenueUser(
    venueUserId: string,
    newPassword: string,
    waiterCode: string
  ) {
    try {
      const result = await this.client.put(`/${venueUserId}`, {
        password: newPassword,
        waiterCode: waiterCode,
      });
      return {
        success: true,
        message: result.data.message,
      };
    } catch (e) {
      const error = e as AxiosError;
      if (error.response?.status !== 409) {
        ErrorHandler.onError(error);
        return;
      }
      return {
        success: false,
        message: error.response.data.message,
      };
    }
  }

  public async deleteVenueUser(venueUserId: string) {
    try {
      const result = await this.client.delete(`/${venueUserId}`);
      return result.data;
    } catch (error) {
      ErrorHandler.onError(error);
      return;
    }
  }
}
