import { cachedKeys } from "@/utils/cache";

const ONE_DAY = 86400000;

function setToken(token: string) {
  const d = new Date(Date.now() + ONE_DAY);
  const expires = "expires=" + d.toUTCString();
  document.cookie = `${cachedKeys.TOKEN}=${token};${expires};path/;secure;samesite;`;
}

function setRefreshToken(token: string) {
  const d = new Date(Date.now() + 14 * ONE_DAY);
  const expires = "expires=" + d.toUTCString();
  document.cookie = `${cachedKeys.REFRESH_TOKEN}=${token};${expires};path/;secure;samesite;`;
}

function getToken() {
  const name = `${cachedKeys.TOKEN}=`;
  const cookiesDecoded = decodeURIComponent(document.cookie); //to be careful
  const cookiesDecodedArray = cookiesDecoded.split("; ");
  let token;
  cookiesDecodedArray.forEach((cookie) => {
    if (cookie.indexOf(name) === 0) token = cookie.substring(name.length);
  });
  return token;
}

function getRefreshToken() {
  const name = `${cachedKeys.REFRESH_TOKEN}=`;
  const cookiesDecoded = decodeURIComponent(document.cookie); //to be careful
  const cookiesDecodedArray = cookiesDecoded.split("; ");
  let token;
  cookiesDecodedArray.forEach((cookie) => {
    if (cookie.indexOf(name) === 0) token = cookie.substring(name.length);
  });
  return token;
}

function deleteToken() {
  document.cookie = `${cachedKeys.TOKEN}=; Max-Age=-1;`;
}

function deleteRefreshToken() {
  document.cookie = `${cachedKeys.REFRESH_TOKEN}=; Max-Age=-1;`;
}

export {
  setToken,
  setRefreshToken,
  getToken,
  getRefreshToken,
  deleteToken,
  deleteRefreshToken,
};
