import type { AnalyticsChart } from "@/types/Venue";

export const groupOrderedTreatsByDate = (
  orderedTreats: AnalyticsChart[]
): [Date, number][] => {
  const grouped: Record<string, number> = {};

  // Step 1: Grouping the treats by date
  orderedTreats.forEach((treat) => {
    const date = new Date(
      new Date(treat.createdAt).setHours(0, 0, 0)
    ).toDateString();

    if (!grouped[date]) grouped[date] = 1;
    else grouped[date] += 1;
  });

  // Step 2: Finding the range of dates
  const dates = Object.keys(grouped).map((date) => new Date(date));
  const minDate = new Date(Math.min(...dates));
  const maxDate = new Date(Math.max(...dates));

  // Step 3: Filling in missing dates with 0s
  const currentDate = new Date(minDate);
  const result: [Date, number][] = [];

  while (currentDate <= maxDate) {
    const dateKey = currentDate.toDateString();
    result.push([new Date(currentDate), grouped[dateKey] || 0]);
    currentDate.setDate(currentDate.getDate() + 1);
  }

  return result;
};
