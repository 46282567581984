<template>
  <div>
    <div class="d-flex justify-start">
      <h1 class="dion_title">Welcome to dion</h1>
    </div>
    <div v-if="venue" class="d-flex flex-column gap-y-5">
      <div
        v-if="customerAnalytics && treatAnalytics"
        class="d-flex flex-column gap-y-2"
      >
        <h2 class="dion_dashboard_header">Live</h2>
        <div class="dion_dashboard_analytics_section">
          <LiveAnalytics
            :total-revenue-today="treatAnalytics.totalRevenueToday"
            :total-treats-today="treatAnalytics.totalTreatsToday"
            :open-check-ins="customerAnalytics.activeUsersCount"
          />
        </div>
        <h2 class="dion_dashboard_header">Last 7 days</h2>
        <div class="dion_dashboard_analytics_section">
          <Last7DaysAnalytics
            :total-revenue="treatAnalytics.totalRevenue"
            :total-treats="treatAnalytics.totalTreats"
            :last-week-check-outs="customerAnalytics.totalCheckIns"
          />
        </div>
      </div>
      <div v-if="treatMetrics" class="d-flex flex-column gap-y-2">
        <h2 class="dion_dashboard_header">All time Rankings</h2>
        <TreatMetrics :treat-metrics="treatMetrics" />
      </div>
      <div
        v-if="customerAnalytics && treatAnalytics && treatMetrics"
        class="d-flex flex-column gap-y-2"
      >
        <h2 class="dion_dashboard_header">Last 7 days</h2>
        <div
          class="d-flex flex-row flex-wrap justify-start gap-10"
          style="width: 100%"
        >
          <TreatsChart :total-treats="treatAnalytics.orderedTreats" />
          <CustomerChart :total-checkins="customerAnalytics.latestCheckIns" />
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.dion_title {
  font-weight: bolder;
  color: #0e1d31;
}
.dion_dashboard_header {
  font-weight: bold;
  color: #0e1d31;
}
.dion_dashboard_analytics_section {
  display: flex;
  flex-direction: row;
  column-gap: 1rem;
  row-gap: 2rem;
  justify-content: start;
}
</style>

<script setup lang="ts">
import { onMounted, ref } from "vue";
import { useVenueStore } from "@/stores/venueStore";
import { AnalyticService } from "@/views/dashboard/services/analyticService";
import TreatMetrics from "@/views/dashboard/components/TreatMetrics.vue";
import TreatsChart from "@/views/dashboard/components/TreatsChart.vue";
import type {
  CustomerAnalytics as CustomerAnalyticsType,
  TreatAnalytics as TreatAnalyticsType,
  TreatMetric,
  VenueType,
} from "@/types/Venue";
import { cache, cachedKeys } from "@/utils/cache";
import CustomerChart from "@/views/dashboard/components/CustomerChart.vue";
import LiveAnalytics from "@/views/dashboard/components/LiveAnalytics.vue";
import Last7DaysAnalytics from "@/views/dashboard/components/Last7DaysAnalytics.vue";

const venueStore = useVenueStore();
const venue = ref({} as VenueType);
const analyticService = new AnalyticService();
const customerAnalytics = ref(null as CustomerAnalyticsType | null);
const treatAnalytics = ref(null as TreatAnalyticsType | null);
const treatMetrics = ref(null as TreatMetric[] | null);
const venueId = cache.getCache(cachedKeys.VENUE_ID);

const syncDashboardAnalytics = async () => {
  customerAnalytics.value = await analyticService.getCustomerAnalytics(venueId);
  treatAnalytics.value = await analyticService.getTreatAnalytics(venueId);
  treatMetrics.value = await analyticService.getTreatMetrics(venueId);
};

onMounted(async () => {
  venue.value = await venueStore.getVenue();
  await syncDashboardAnalytics();
});
</script>
