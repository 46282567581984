<template>
  <VueApexCharts
    type="bar"
    :options="chartOptions"
    :series="series"
    class="analytic_chart"
  ></VueApexCharts>
</template>

<style scoped>
/* Add styles if needed */
</style>

<script setup lang="ts">
import { type PropType, ref } from "vue";
import VueApexCharts from "vue3-apexcharts";
import type { AnalyticsChart } from "@/types/Venue";
import { groupOrderedTreatsByDate } from "@/views/dashboard/services/charts";
import "../styles/analitycs.css";

const props = defineProps({
  totalTreats: {
    type: Array as PropType<AnalyticsChart[]>,
    required: true,
  },
});
const primaryColor = "#0E1D31";

const series = ref([
  {
    name: "Total treats",
    data: groupOrderedTreatsByDate(props.totalTreats),
    color: primaryColor,
  },
]);

// Chart options
const chartOptions = ref({
  chart: {
    type: "bar",
    zoom: {
      enabled: false,
    },
    toolbar: {
      export: {
        csv: {
          filename: "total_treats_chart",
        },
        svg: {
          filename: "total_treats_chart",
        },
        png: {
          filename: "total_treats_chart",
        },
      },
    },
  },
  plotOptions: {
    bar: {
      horizontal: false,
      columnWidth: "15%",
    },
  },
  stroke: {
    show: true,
    width: 2,
    colors: ["transparent"],
  },
  fill: {
    opacity: 0.85,
    colors: [primaryColor],
  },
  title: {
    text: "Total treats per day",
    align: "left",
  },
  xaxis: {
    type: "datetime",
  },
  yaxis: {
    title: {
      text: "Treats",
    },
    min: 0,
    labels: {
      formatter: function (value: number) {
        return Math.round(value); // Round to nearest integer
      },
    },
  },
  tooltip: {
    x: {
      format: "yyyy-MM-dd",
    },
  },
});
</script>
