import { defineStore } from "pinia";
import type { OfferOrder as OfferOrderType } from "@/types/Venue";
import type { TreatOrder as TreatOrderType } from "@/types/Venue";
import { cachedKeys } from "@/utils/cache";
import { ref } from "vue";
import { OrderService } from "@/services/orderService";
import { currentMonthFirstDay } from "@/common/dates";

export const useOfferOrderStore = defineStore(cachedKeys.OFFER_ORDER, () => {
  const offerOrders = ref({} as Record<string, OfferOrderType>);
  const lastFromDate = ref(null as Date | null);
  const lastToDate = ref(null as Date | null);
  const orderService = new OrderService();

  function getAllOrders(): Record<string, OfferOrderType> {
    return offerOrders.value;
  }

  const syncOrderedOffers = async (
    dates?: {
      fromDate: string | null;
      toDate: string | null;
    },
    fromSocketEvent = false
  ): Promise<void> => {
    if (fromSocketEvent && !watchingLiveOrders()) return;

    lastFromDate.value = dates?.fromDate ? new Date(dates.fromDate) : null;
    lastToDate.value = dates?.toDate ? new Date(dates.toDate) : null;

    const orderedOffers = await orderService.getOrderedOffers({
      fromDate: dates ? dates.fromDate : null,
      toDate: dates ? dates.toDate : null,
    });

    if (orderedOffers) {
      const mappedOfferOrders = {} as Record<string, OfferOrderType>;
      for (const orderedOffer of orderedOffers) {
        mappedOfferOrders[orderedOffer.id] = orderedOffer;
      }
      offerOrders.value = mappedOfferOrders;
    }
  };

  const watchingLiveOrders = () =>
    lastToDate.value && new Date().getTime() < lastToDate.value.getTime();

  function getNotAcknowledgedLength(): number {
    return Object.keys(offerOrders.value).filter((order) => {
      return !offerOrders.value[order].acknowledged;
    }).length;
  }

  function checkOfferOrderExistence(offerOrder: OfferOrderType) {
    return offerOrders.value[offerOrder.id] === undefined;
  }

  function addOfferOrder(offerOrder: OfferOrderType) {
    offerOrders.value[offerOrder.id] = offerOrder;
  }

  function acknowledgeOfferOrder(offerOrderId: string) {
    offerOrders.value[offerOrderId].acknowledged = true;
    offerOrders.value[offerOrderId].acknowledgedAt = new Date();
  }

  function dismissOfferOrder(offerOrderId: string) {
    offerOrders.value[offerOrderId].dismissed = true;
    offerOrders.value[offerOrderId].dismissedAt = new Date();
  }

  function setOrderedOffers(orderedOffers: Record<string, OfferOrderType>) {
    offerOrders.value = orderedOffers;
  }

  return {
    getAllOrders,
    syncOrderedOffers,
    getNotAcknowledgedLength,
    checkOfferOrderExistence,
    addOfferOrder,
    acknowledgeOfferOrder,
    dismissOfferOrder,
    setOrderedOffers,
  };
});

export const useTreatOrderStore = defineStore(cachedKeys.TREAT_ORDER, () => {
  const treatOrders = ref({} as Record<string, TreatOrderType>);
  const lastFromDate = ref(null as Date | null);
  const lastToDate = ref(null as Date | null);
  const orderService = new OrderService();

  const getAllOrders = (): Record<string, TreatOrderType> => {
    return treatOrders.value;
  };

  const getNotAcknowledgedLength = (): number => {
    return Object.keys(treatOrders.value).filter((order) => {
      return !treatOrders.value[order].acknowledged;
    }).length;
  };

  const checkTreatOrderExistence = (offerOrder: TreatOrderType) => {
    return treatOrders.value[offerOrder.id] === undefined;
  };

  const addOTreatOrder = (offerOrder: TreatOrderType) => {
    treatOrders.value[offerOrder.id] = offerOrder;
  };

  const acknowledgeTreatOrder = (treatOrderId: string) => {
    treatOrders.value[treatOrderId].acknowledged = true;
    treatOrders.value[treatOrderId].acknowledgedAt = new Date();
  };

  const setOrderedTreats = (orderedTreats: Record<string, TreatOrderType>) => {
    treatOrders.value = orderedTreats;
  };

  const clearAllOrderedTreats = () => {
    treatOrders.value = {};
  };

  const syncOrderedTreats = async (
    dates?: {
      fromDate: string | null;
      toDate: string | null;
    },
    fromSocketEvent = false
  ) => {
    if (fromSocketEvent && !watchingLiveOrders()) return;

    lastFromDate.value = dates?.fromDate
      ? new Date(dates.fromDate)
      : new Date(currentMonthFirstDay());
    lastToDate.value = dates?.toDate ? new Date(dates.toDate) : new Date();

    const orderedTreats = await orderService.getOrderedTreats({
      fromDate: lastFromDate.value.toString(),
      toDate: lastToDate.value.toString(),
    });

    if (orderedTreats) {
      const mappedTreatOrders = {} as Record<string, TreatOrderType>;
      for (const orderedTreat of orderedTreats) {
        mappedTreatOrders[orderedTreat.id] = orderedTreat;
      }
      treatOrders.value = mappedTreatOrders;
    }
  };

  const watchingLiveOrders = () =>
    lastToDate.value && new Date().getTime() < lastToDate.value.getTime();

  return {
    getAllOrders,
    getNotAcknowledgedLength,
    checkTreatOrderExistence,
    addOTreatOrder,
    acknowledgeTreatOrder,
    setOrderedTreats,
    clearAllOrderedTreats,
    syncOrderedTreats,
  };
});
