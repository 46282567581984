import { Icon } from "@iconify/vue";
import type { IconProps, IconSet } from "vuetify";
import { h } from "vue";

export const iconify: IconSet = {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  component: (props: IconProps) => h(Icon, props),
};

export const icons = {
  defaultSet: "iconify",
  sets: {
    iconify,
  },
};
