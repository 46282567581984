<template>
  <v-data-table
    :headers="headers"
    :items="treatMetrics"
    v-model:sort-by="sortBy"
    v-model:sort-desc="sortDesc"
    :items-per-page="5"
    class="metrics_table"
  ></v-data-table>
</template>

<script setup lang="ts">
import type { PropType } from "vue";
import type { TreatMetric } from "@/types/Venue";
import { ref } from "vue";
import { formatPrice } from "@/common/Amount";

defineProps({
  treatMetrics: {
    type: Array as PropType<TreatMetric[]>,
    required: true,
  },
});

const sortBy = ref([]);
const sortDesc = ref([]);

const headers = [
  { title: "Item", value: "treatName" },
  {
    title: "Times ordered",
    value: "timesOrdered",
    key: "timesOrdered",
  },
  {
    title: "Total revenue",
    key: "totalRevenue.amount",
    value: (treatMetric: TreatMetric) =>
      `${formatPrice(treatMetric.totalRevenue)}`,
  },
];
</script>
