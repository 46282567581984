<template>
  <label
    :for="`upload_drink_proto_${props.id}`"
    class="upload-button-container"
  >
    <v-icon icon="mdi-image-edit-outline" size="24" />
  </label>
  <input
    type="file"
    name="photo"
    :id="`upload_drink_proto_${props.id}`"
    class="custom-file-input"
    :accept="supportedImageTypes"
    @change="fileInput"
    alt="Submit"
  />
  <n-modal v-model:show="showCropper" class="dion-modal" id="crop-modal">
    <n-space vertical>
      <n-space justify="end" class="closeButton">
        <n-button color="#FFF" text-color="black" @click="showCropper = false">
          <n-icon size="30">
            <CloseIcon />
          </n-icon>
        </n-button>
      </n-space>
      <n-space vertical align="center">
        <VueCropper
          v-if="toBeCropped && imageHeight"
          :src="toBeCropped"
          ref="cropper"
          :aspectRatio="aspectRatio"
          :data="{
            height: imageHeight,
            width: imageHeight * aspectRatio,
          }"
          alt="Image Cropper"
          class="cropper"
        ></VueCropper>
        <n-space justify="center">
          <v-btn @click="cropImage" color="primary" class="crop-button">
            Crop
          </v-btn>
        </n-space>
        <Loader v-if="loading" :loader="loading" />
      </n-space>
    </n-space>
  </n-modal>
</template>

<style>
.upload-button-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 3px;
  color: #0e1d31;
  opacity: 1;
  border-style: solid;
  border-radius: 10px;
}

.closeButton {
  margin: 10px;
}

label {
  cursor: pointer;
}

#upload-photo {
  opacity: 0;
  position: absolute;
  z-index: -1;
}

#crop-modal {
  max-height: 80vh;
  max-width: 600px;
}

.cropper {
  max-height: 55vh;
  max-width: 500px;
  margin: 15px;
}

.crop-button {
  margin-bottom: 20px;
}
.custom-file-input {
  opacity: 0;
  display: none;
  position: absolute;
  z-index: -1;
}
</style>
<script setup lang="ts">
import { NButton, NIcon, NModal, NSpace, useNotification } from "naive-ui";
import { ref } from "vue";
import { CloseOutline as CloseIcon } from "@vicons/ionicons5";
import VueCropper from "vue-cropperjs";
import "cropperjs/dist/cropper.css";
import { ImagesService } from "@/services/imagesService";
import Loader from "@/components/Loader.vue";
import { DrinkService } from "@/views/menus/drinks/services/drinkService";

const emit = defineEmits(["drinkImageUploaded"]);

const showCropper = ref(false);
const cropper = ref();
const toBeCropped = ref("" as string | null);
const aspectRatio = ref(200 / 262);
const imageHeight = ref(0);
const loading = ref(false);
const notification = useNotification();
const imageService = new ImagesService();
const drinkService = new DrinkService();
const supportedImageTypes = [
  "image/png",
  "image/jpg",
  "image/jpeg",
  "image/heic",
  "image/webp",
].join(",");

const props = defineProps({
  id: String,
});

const toBase64 = (file: File | Blob) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

interface InputFileEvent extends Event {
  target: HTMLInputElement;
}

async function fileInput(event: InputFileEvent) {
  let file = event.target.files![0];
  if (file.type == "image/heic") {
    file = (await imageService.convertHeicToPng(file)) as File;
  }

  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = function (e) {
    const img = new Image();
    img.src = e.target?.result;
    img.onload = function () {
      imageHeight.value = this.height;
    };
  };

  toBeCropped.value = String(await toBase64(file));

  showCropper.value = true;
}

function cropImage() {
  cropper.value.getCroppedCanvas().toBlob(
    async (blob: Blob) => {
      const formData = new FormData();
      formData.append("file", blob as File);
      await uploadImage(formData);
    },
    "image/webp",
    0.5
  );
}

function closeCropperAndLoader() {
  showCropper.value = false;
  loading.value = false;
}

async function uploadImage(formData: FormData) {
  loading.value = true;
  const result = await drinkService.uploadDrinkImage(props.id!, formData);

  if (!result) {
    notification.error({ content: "Something went wrong", duration: 2000 });
    closeCropperAndLoader();
    return;
  }

  if (result.success) {
    emit("drinkImageUploaded", result.imageUrl);
    closeCropperAndLoader();
    return;
  }

  const errorMessage = result.message;
  notification.error({ content: errorMessage, duration: 2000 });
  closeCropperAndLoader();
  return;
}
</script>
