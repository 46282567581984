import { defineStore } from "pinia";
import { cachedKeys } from "@/utils/cache";
import { ref } from "vue";
import { VenueUserService } from "@/services/venueUserService";
import type { VenueUser } from "@/views/waiters/types/VenueUser";
import { VenueUserRole } from "@/views/waiters/types/VenueUser";

export const useVenueUserStore = defineStore(cachedKeys.VENUE_USERS, () => {
  const venueUsers = ref([] as VenueUser[]);

  function initStore() {
    new VenueUserService().getAllVenueUsers().then((allVenueUsers) => {
      if (allVenueUsers)
        venueUsers.value = allVenueUsers.sort(
          (a, b) => Number(a.id) - Number(b.id)
        );
    });
  }
  async function getAllVenueUsers() {
    const allVenueUsers = await new VenueUserService().getAllVenueUsers();
    if (allVenueUsers)
      venueUsers.value = allVenueUsers.sort(
        (a, b) => Number(a.id) - Number(b.id)
      );
  }

  function getAllWaitersAndCashiers(): VenueUser[] {
    return venueUsers.value.filter(
      (user) =>
        user.role == VenueUserRole.WAITER || user.role == VenueUserRole.CASHIER
    );
  }

  function addVenueUser(venueUser: VenueUser) {
    venueUsers.value.push(venueUser);
  }

  function deleteVenueUser(venueUserId: string) {
    const index = venueUsers.value.findIndex(
      (venueUser) => venueUser.id === venueUserId
    );
    venueUsers.value.splice(index, 1);
  }

  initStore();

  return {
    getAllWaitersAndCashiers,
    getAllVenueUsers,
    addVenueUser,
    deleteVenueUser,
  };
});
