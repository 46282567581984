<template>
  <BreadCrumb />
  <v-card class="my-2">
    <v-tabs
      v-model="currentTab"
      bg-color="on-secondary"
      color="primary"
      stacked
      centered
    >
      <v-tab :value="`Treating menu`">
        <div class="py-1 text-md font-weight-bold">Treating menu</div>
      </v-tab>
      <v-tab
        v-for="categoryName in Object.keys(categorizedDrinks)"
        :value="capitalizeName(categoryName)"
        :key="categoryName"
      >
        <div class="py-1 text-md font-weight-bold">
          {{ capitalizeName(categoryName) }}
        </div>
      </v-tab>
    </v-tabs>

    <v-card-text>
      <v-window v-model="currentTab">
        <v-window-item :name="`Treating menu`" :value="`Treating menu`">
          <TreatingMenuTable :customTreats="customTreats" />
        </v-window-item>
        <v-window-item
          v-for="categoryName in Object.keys(categorizedDrinks)"
          :name="capitalizeName(categoryName)"
          :value="capitalizeName(categoryName)"
          :key="categoryName"
        >
          <DrinksTable
            :drinks="categorizedDrinks[categoryName]"
            :options="categorizedOptions['spirits']"
          />
        </v-window-item>
      </v-window>
    </v-card-text>
  </v-card>
  <Loader :loader="loading" v-if="loading" />
</template>

<script setup lang="ts">
import BreadCrumb from "@/components/BreadCrumb.vue";
import { onMounted, ref, watch } from "vue";
import Loader from "@/components/Loader.vue";
import type {
  CustomTreatFromResponse,
  Drink,
  DrinkFromResponse,
  Option,
} from "@/views/menus/drinks/types/drinks";
import DrinksTable from "@/views/menus/drinks/components/DrinksTable.vue";
import { useDrinkStore } from "@/views/menus/drinks/stores/drinksStore";
import TreatingMenuTable from "@/views/menus/drinks/components/TreatingMenuTable.vue";

const drinkStore = useDrinkStore();
const loading = ref(true);
const currentTab = ref("Treating menu");
const availableDrinks = ref([] as DrinkFromResponse[]);

type categorizedDrinksType = Record<string, Drink[]>;

const categorizedDrinks = ref({} as categorizedDrinksType);
const categorizedOptions = ref({} as Record<string, Option[]>);
const customTreats = ref([] as CustomTreatFromResponse[]);

const orderDrinksByCategory = () => {
  categorizedDrinks.value["User favourites"] =
    availableDrinks.value.map(toDrink);
};

const toDrink = (drink: DrinkFromResponse): Drink => ({
  id: drink.id,
  name: drink.name,
  originalName: drink.originalName,
  imageUrl: drink.imageUrl,
  price: drink.price,
  originalPrice: drink.originalPrice,
  enabled: drink.enabled,
  sizes: drink.sizes,
  menuItem: drink.menuItem || null,
});

const setDrinks = () => {
  availableDrinks.value = drinkStore.getDrinks();
};

const setOptions = () => {
  categorizedOptions.value = drinkStore.getOptionGroups();
};

const setCustomTreats = () => {
  customTreats.value = drinkStore.getCustomTreats();
};

const capitalizeName = (name: string) => {
  return name.charAt(0).toUpperCase() + name.slice(1);
};

watch(
  () => drinkStore.getCustomTreats(),
  () => {
    setCustomTreats();
  }
);

onMounted(async () => {
  setDrinks();
  orderDrinksByCategory();
  setOptions();
  setCustomTreats();
  loading.value = false;
  await drinkStore.fetchVenueDrinks();
});
</script>
