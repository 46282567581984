import { cache, cachedKeys } from "@/utils/cache";
import axios from "axios";
import * as Sentry from "@sentry/vue";
import { BrowserTracing } from "@sentry/tracing";
import router from "@/router";
import type { Vue } from "@sentry/vue/types/types";
import { deleteRefreshToken, deleteToken } from "@/utils/token";

const API_HOST = import.meta.env.VITE_API_HOST;
const SENTRY_DSN = import.meta.env.VITE_SENTRY_DSN;
const SENTRY_RELEASE = import.meta.env.COMMIT_REF;
const NODE_ENV = import.meta.env.VITE_NODE_ENV;

export default class ErrorHandler {
  static onError(error: any) {
    if (axios.isAxiosError(error)) {
      const response = error.response;
      if (response) {
        switch (response.status) {
          case 401:
            deleteToken();
            cache.setCache(
              cachedKeys.LAST_VISITED_ROUTE,
              router.currentRoute.value.name
            );
            window.location.reload();
            return;
          case 403:
            deleteToken();
            deleteRefreshToken();
            cache.setCache(
              cachedKeys.LAST_VISITED_ROUTE,
              router.currentRoute.value.name
            );
            window.location.reload();
            return;
        }
      }
    }
    Sentry.captureException(error);
    return;
  }

  static initSentry(app: Vue) {
    Sentry.init({
      app,
      dsn: SENTRY_DSN,
      release: SENTRY_RELEASE,
      integrations: [
        new BrowserTracing({
          routingInstrumentation: Sentry.vueRouterInstrumentation(router),
          tracingOrigins: [API_HOST, /^\//],
        }),
      ],
      environment: NODE_ENV,
      tracesSampleRate: 1.0,
    });
  }
}
