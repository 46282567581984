<template>
  <div></div>
  <!-- The template requires child element -->
</template>

<script setup lang="ts">
import { socket as SocketService } from "@/services/websocketService";
import { onMounted } from "vue";
import { useTreatOrderStore } from "@/stores/orderStore";
import { useNotification } from "naive-ui";
import { DateTime } from "luxon";

const notification = useNotification();
const venueSocket = SocketService.get().venueSocket;
const treatOrders = useTreatOrderStore();

venueSocket.on("venue.order.treat", async () => {
  await treatOrders.syncOrderedTreats(undefined, true);
});

venueSocket.on(
  "venue.order.offer",
  async (payload: { title: string; body: string }) => {
    createNotification(payload);
  }
);

function createNotification(message: { title: string; body: string }) {
  return notification.create({
    title: message.title,
    content: message.body,
    meta: DateTime.now().toLocaleString(DateTime.DATETIME_SHORT),
    duration: 3000,
    closable: false,
  });
}

onMounted(async () => {
  await treatOrders.syncOrderedTreats();
});
</script>
