<template>
  <div class="location-component dion-primary-color" v-if="activeVenue.id">
    <n-icon class="location-icon dion-primary-color">
      <LocationIcon />
    </n-icon>
    <div class="venue_name">
      {{ activeVenue.venue_name }}
    </div>
    <n-popconfirm
      v-if="venues.length > 1"
      :show-icon="false"
      :negative-text="null"
      :positive-text="null"
      class="venues_wrapper"
      style="border-radius: 25px"
    >
      <template #trigger>
        <n-button
          quaternary
          @click="showModal = !showModal"
          @blur="showModal = false"
          color="#0E1D31"
          class="trigger_button"
        >
          <template #icon>
            <n-icon size="15">
              <OpenedIcon v-if="showModal" />
              <ClosedIcon v-else />
            </n-icon>
          </template>
        </n-button>
      </template>
      <div class="d-flex flex-column overflow-auto" style="max-height: 300px">
        <div
          style="
            position: sticky;
            top: 0;
            z-index: 100;
            background-color: white;
            padding: 10px;
          "
        >
          <v-text-field
            v-model="searchText"
            placeholder="Search venues"
            variant="underlined"
            style="min-width: 100px"
          ></v-text-field>
        </div>
        <div v-for="venue in filteredVenues" :key="venue.id">
          <n-button
            quaternary
            color="#0e1d31"
            v-if="venue.id !== Number(activeVenue.id)"
            @click="changeVenue(venue.id)"
            style="width: 100%; display: flex; justify-content: start"
          >
            {{ venue.venueName }}
          </n-button>
        </div>
      </div>
    </n-popconfirm>
  </div>
  <Loader :loader="loader" v-if="loader" />
</template>

<style scoped>
@import "@/styling/global.css";
.location-component {
  margin: auto 0;
  display: flex;
}
.location-icon {
  margin: auto 5px;
}
.venue_name {
  margin: auto 0;
}
:global(.n-popover-arrow) {
  display: none !important;
}
.trigger_button {
  width: 25px;
}

::-webkit-scrollbar {
  display: none;
}
</style>

<script setup lang="ts">
import { NIcon, useNotification, NButton, NPopconfirm } from "naive-ui";
import { Location as LocationIcon } from "@vicons/ionicons5";
import { computed, onMounted, ref } from "vue";
import { NewVenueService } from "@/services/newVenueService";
import Loader from "@/components/Loader.vue";
import {
  ChevronRight as ClosedIcon,
  ChevronDown as OpenedIcon,
} from "@vicons/fa";
import { useVenueStore } from "@/stores/venueStore";
import type { VenueType } from "@/types/Venue";

const newVenueService = new NewVenueService();
const notification = useNotification();
const venues = ref([] as { id: number; venueName: string; imageUrl: string }[]);
const showModal = ref(false);
const loader = ref(false);
const venueStore = useVenueStore();
const activeVenue = ref({} as VenueType);
const searchText = ref("");

const getAllVenues = async () => {
  const response = await newVenueService.getAccessibleVenues();

  if (!response) {
    notification.error({ content: "Something went wrong", duration: 2000 });
    return;
  }

  venues.value = response;
};

const normalizeText = (text: string) => {
  return text
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "")
    .toLowerCase();
};

const filteredVenues = computed(() => {
  const normalizedQuery = normalizeText(searchText.value);
  return venues.value.filter((venue) =>
    normalizeText(venue.venueName).includes(normalizedQuery)
  );
});

const changeVenue = async (venueId: number) => {
  loader.value = true;
  await newVenueService.updateVenueUsersCurrentVenue(venueId);
};

onMounted(async () => {
  activeVenue.value = await venueStore.getVenue();
  await getAllVenues();
});
</script>
