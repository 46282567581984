<template>
  <div v-for="item in statistics" :key="item.title" class="analytic_card">
    <div>
      <div class="analytic_card_avatar">
        <VAvatar :color="'primary'" rounded size="50" class="elevation-1">
          <VIcon size="24" :icon="item.icon" />
        </VAvatar>
      </div>

      <div class="d-flex flex-column flex-nowrap">
        <span class="font-weight-bold">
          {{ item.title }}
        </span>
        <span class="text-h6 font-weight-bold">{{ item.stats }}</span>
        <span
          class="text-teal font-weight-bold"
          style="opacity: 0.8; color: grey"
          >{{ item.subtitle }}</span
        >
      </div>
    </div>
  </div>
</template>

<style scoped></style>

<script setup lang="ts">
import { onMounted, type PropType, ref, watch } from "vue";
import "../styles/analitycs.css";
import { type Amount, formatPrice } from "@/common/Amount";

const props = defineProps({
  openCheckIns: {
    type: Number,
    required: true,
  },
  totalTreatsToday: {
    type: Number,
    required: true,
  },
  totalRevenueToday: {
    type: Object as PropType<Amount>,
    required: true,
  },
});
const statistics = ref(
  [] as { title: string; stats: number; subtitle: string; icon: string }[]
);

const constructStatistics = () => {
  statistics.value = [
    {
      title: "Checked-in Users",
      stats: props.openCheckIns!,
      subtitle: "Now",
      icon: "mdi-people-add-outline",
    },
    {
      title: "Treats",
      stats: props.totalTreatsToday!,
      subtitle: "Today",
      icon: "mdi-glass-cocktail",
    },
    {
      title: "Revenue",
      stats: formatPrice(props.totalRevenueToday)!,
      subtitle: "Today",
      icon: "mdi-cash-multiple",
    },
  ];
};

watch(
  () => props.openCheckIns,
  () => constructStatistics()
);

onMounted(() => {
  constructStatistics();
});
</script>
