import { createRouter, createWebHistory } from "vue-router";
import LoggedInLayout from "../views/LoggedInLayout.vue";
import Dashboard from "../views/dashboard/Dashboard.vue";
import Login from "../views/Login.vue";
import Drinks from "@/views/menus/drinks/Drinks.vue";
import Orders from "@/views/orders/Orders.vue";
import { setupLayouts } from "virtual:generated-layouts";

import { getRefreshToken, getToken } from "@/utils/token";
import { VenueUserRole } from "@/views/waiters/types/VenueUser";
import { cache, cachedKeys } from "@/utils/cache";
import { AuthService } from "@/services/authService";

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  linkActiveClass: "active", // active class for non-exact links.
  linkExactActiveClass: "active", // active class for *exact* links,
  routes: [
    ...setupLayouts([
      {
        path: "/auth/login",
        name: "login",
        component: Login,
      },

      {
        path: "",
        component: LoggedInLayout,
        children: [
          {
            path: "",
            name: "dashboard",
            component: Dashboard,
          },
          {
            path: "/treating_menu",
            name: "treating menu",
            component: Drinks,
            meta: { orderingAuth: true },
          },
          {
            path: "/orders",
            name: "orders",
            component: Orders,
          },
        ],
      },
    ]),
  ],
});

router.beforeEach(async (to) => {
  const token = getToken();

  if (to.path !== "/auth/login" && !token) {
    if (await tryToRefreshToken()) return to.path;
    return "/auth/login";
  }

  const venueUserRole = cache.getCache(cachedKeys.ROLE);
  const orderingEnabled = cache.getCache(cachedKeys.ORDERING_ENABLED);

  if (
    (token && to.path === "/auth/login") ||
    (to.meta.managerAuth && !isAdminOrManager(venueUserRole)) ||
    (to.meta.orderingAuth && !orderingEnabled)
  )
    return "/";
});

function isAdminOrManager(venueUserRole: string) {
  return (
    venueUserRole === VenueUserRole.MANAGER ||
    venueUserRole === VenueUserRole.ADMIN
  );
}

async function tryToRefreshToken() {
  const refreshToken = getRefreshToken();
  if (refreshToken) {
    return await new AuthService().refreshToken(refreshToken);
  }
  return;
}

export default router;
