export type NewOffer = {
  title: string | null;
  description: string | null;
  discount: Discount | null;
  image_url: string | null;
  is_active: boolean;
  label: string | null;
  availability: OfferAvailability | null;
  category: OfferCategories;
  type: OfferType;
  quantity: null | number;
};

export type Offer = {
  id: string;
  createdAt: string;
  updatedAt: string;
  title: string;
  description: string;
  label: string;
  discount: Discount;
  image_url: string;
  is_active: boolean;
  order: number;
  availability: OfferAvailability | null;
  category: OfferCategories;
  type: OfferType;
  quantity: null | number;
};

export enum OfferType {
  SUGGESTION = "SUGGESTION",
  OFFER = "OFFER",
}

export enum OfferTitle {
  OFFER = "Offer",
  SUGGESTION = "Suggestion",
}

export enum OfferCategories {
  NON_EVENT = "NON_EVENT",
  EVENT = "EVENT",
}

export type OfferAvailability = {
  from: string;
  to: string;
};

export type EditOffer = {
  id: string | undefined;
  title?: string | undefined;
  description?: string | undefined;
  discount?: Discount | null;
  image_url?: string | undefined;
  is_active?: boolean | undefined;
  availability?: OfferAvailability | null | undefined;
  label?: string | undefined | null;
  order?: number;
  category?: string;
  type?: string;
  quantity?: number | null;
};

export type Discount = {
  price_old: number | undefined;
  price_new: number | undefined;
};

export enum LabelOptions {
  HAPPY_HOUR = "Happy Hour",
  FEW_LEFT = "Few left",
  LIMITED_TIME = "Limited time",
  SPECIAL = "Special",
  COLLECTIVE = "Collective",
  ONE_PLUS_ONE = "1+1",
  TWO_PERCENT = "-20%",
}
