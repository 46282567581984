<template>
  <div>
    <n-badge color="#0E1D31">
      <n-button
        class="drink_size_button"
        text-color="#000000"
        ghost
        @click="showModal = true"
      >
        {{ size.quantity }}
      </n-button>
      <template #value>
        <n-icon
          v-if="size.price.amount"
          size="15"
          :component="CheckIcon"
          color="#FFF"
        />
      </template>
    </n-badge>
    <v-dialog
      v-model="showModal"
      class="dion-dialog"
      width="auto"
      height="auto"
    >
      <v-card>
        <v-card-title>
          <div class="d-flex justify-end" style="margin-top: 10px">
            <n-button color="#FFF" text-color="black" @click="closeModal">
              <n-icon size="30">
                <CloseIcon />
              </n-icon>
            </n-button>
          </div>
        </v-card-title>
        <v-card-text>
          <div class="drink_size_price_modal">
            <n-checkbox
              size="large"
              label="Availability"
              v-model:checked="enabled"
              :disabled="!editSize.price.amount"
              @update:checked="editSizeAvailability"
              style="margin: 0 auto"
            />
            <n-input-number
              clearable
              :precision="2"
              :step="1"
              v-model:value="editSize.price.amount"
              placeholder="Set price"
              class="price_input"
              @update:value="emitUpdatedSize"
            >
              <template #suffix>
                {{ Currencies[size.price.currency] }}
              </template>
            </n-input-number>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<style scoped>
.drink_size_button {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 18px 12px;
  gap: 10px;
  background: #ffffff;
  border: 1px solid rgba(210, 210, 215, 0.4);
  border-radius: 8px;
}
.drink_size_price_modal {
  display: flex;
  flex-direction: column;
  align-content: center;
  gap: 20px;
  padding: 0 20px 50px 20px;
}
.price_input {
  min-width: 150px;
}
</style>

<script setup lang="ts">
import {
  NIcon,
  NButton,
  NSpace,
  NModal,
  NBadge,
  NInputNumber,
  NCheckbox,
} from "naive-ui";
import {
  CloseOutline as CloseIcon,
  CheckmarkOutline as CheckIcon,
} from "@vicons/ionicons5";
import type { PropType } from "vue";
import type { Size } from "@/views/menus/drinks/types/drinks";
import { onMounted, ref, watch } from "vue";
import { CurrencyDivider, Currencies } from "@/types/Venue";
import { MenuItem } from "@/views/menus/drinks/types/drinks";
import { useItemStore } from "@/views/menus/catalogs/stores/itemsStore";
import { Item } from "@/views/menus/catalogs/types/Menu";

const props = defineProps({
  size: Object as PropType<Size>,
});
const emits = defineEmits(["updatedSize"]);

const editSize = ref({} as Size);
const enabled = ref(false);
const showModal = ref(false);
const itemsForSelect = ref([] as MenuItem[]);
const items = ref([] as Item[]);

const itemStore = useItemStore();

const setEditSize = (size: Size) => {
  editSize.value = {
    ...size,
    price: {
      amount:
        size.price && size.price.amount
          ? dividePriceByCurrency(size.price.amount, size.price.currency)
          : null,
      currency: size.price.currency,
    },
    menuItem: size.menuItem
      ? size.menuItem
      : { id: undefined, name: undefined },
  };
  enabled.value = editSize.value.price.amount !== null;
};

const editSizeAvailability = (value: boolean) => {
  enabled.value = value;
  if (!enabled.value) editSize.value.price.amount = null;

  emitUpdatedSize(null);
};

const updateDrinksMenuItemId = async () => {
  let menuItem = null;
  if (editSize.value.menuItem?.id)
    menuItem = items.value.find(
      (item) => item.id == editSize.value.menuItem!.id
    );
  editSize.value.menuItem = menuItem
    ? { id: menuItem.id, name: menuItem.name }
    : null;

  emitUpdatedSize();
};
const dividePriceByCurrency = (price: number, currency: string) =>
  price / CurrencyDivider[currency as keyof typeof CurrencyDivider];

const emitUpdatedSize = (price?: number | null) => {
  let priceToSet;
  if (price == undefined) priceToSet = editSize.value.price.amount;
  else priceToSet = price;

  const multipliedPrice = priceToSet
    ? multiplyPriceByCurrency(priceToSet, editSize.value.price.currency)
    : null;

  const priceObject = {
    amount: multipliedPrice,
    currency: editSize.value.price.currency,
  };

  enabled.value = price !== null;

  emits("updatedSize", {
    quantity: editSize.value.quantity,
    price: priceObject,
    menuItem: editSize.value.menuItem,
  });
};

const multiplyPriceByCurrency = (price: number, currency: string) =>
  price * CurrencyDivider[currency as keyof typeof CurrencyDivider];

const closeModal = () => {
  showModal.value = false;
};

const menuItemSearch = async (searchText: string) => {
  if (searchText == editSize.value.menuItem?.name) return;

  await itemStore.fetchItems(1, 20, searchText);
};

const onMenuItemClear = () => {
  editSize.value.menuItem = { id: undefined, name: undefined };
};

const constructItemsToSelect = () => {
  itemsForSelect.value = items.value.map((item) => ({
    id: item.id,
    name: item.name,
  }));
  if (editSize.value.menuItem?.id)
    itemsForSelect.value.push(editSize.value.menuItem);
};

watch(
  () => itemStore.getItems(),
  () => {
    items.value = itemStore.getItems();
    constructItemsToSelect();
  }
);

onMounted(() => {
  if (props?.size && props.size !== ({} as Size)) setEditSize(props.size);
  items.value = itemStore.getItems();
  constructItemsToSelect();
});
</script>
